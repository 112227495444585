import { pushCustomTagToClarity } from "./Clarity";

const ExternalPlatformLogo = (props) => {
    const { src, alt, link } = props
    return (
        <div>
            <a href={link} target="_blank" rel="noopener noreferrer" onClick={() => pushCustomTagToClarity("external-logo-click", link)}>
                <div className="transition-all h-8 w-8 opacity-80 hover:opacity-100 duration-500">
                    <img
                        className='rounded-full'
                        src={src}
                        alt={alt}
                    />
                </div>
            </a>
        </div>
    )
}

export default ExternalPlatformLogo;