const PageSection = ({ className, ref, subClassName, children }) => {
    return (
        <div className={`flex justify-center ${className}`}>
            <div ref={ref} className={`md:w-3/4 px-8 md:px-0 max-w-screen-2xl ${subClassName}`}>
                {children}
            </div>
        </div>
    );
};

export default PageSection;