import React from 'react';

import { Landing, SelectedProjects } from '../components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/SEO';
import PageColumn from '../components/PageColumn';
import PageSection from '../components/PageSection';
import BlogThumbnails from '../components/BlogThumbnails';

const Home = () => {

    return (
        <div className="bg-darkblack">
            <SEO
                description="Discover Kadyrai Zaruova's Product Design Portfolio. A blend of UX/UI and innovative product solutions. Explore a collection of user-centric designs that marry functionality and aesthetics; reflecting design trends in the current digital realm."
            />
            <Header />
            <PageColumn>
                <Landing />

                <SelectedProjects />

                {/* <PageSection>
                    <BlogThumbnails title={"Blog Posts"} />
                </PageSection> */}

                <PageSection className={`!justify-normal md:!justify-center`}>
                    <Footer/>
                </PageSection>

            </PageColumn>
        </div>
    )
}

export default Home;