import GradientBorder from "./GradientBorder"

const Bubble = ({ text }) => {
    return (
        <div className="flex">
            <GradientBorder colorRGB={[255, 255, 255]} viaColorRGB={[ 255, 255, 255]} passiveBorder={true} followsMouse={false} className="p-0.5 rounded-full">
                <div className="py-2 pl-4 pr-4 font-gilroy bg-transparent text-lg rounded-full text-gray-400 cursor-default select-none transition duration-500">
                    #{text}
                </div>
            </GradientBorder>
        </div>
    )
}

export default Bubble;