import { useState, useEffect, useRef } from 'react';

import logo from '../assets/skillworks/skillworks_logo.svg'
import sw_collage from '../assets/skillworks/skillworks_top_collage.png'
import sw_old_design from '../assets/skillworks/skillworks_old_design.png'
import sw_information_architecture from '../assets/skillworks/skillworks_info_architecture.png'
import sw_user_flows from '../assets/skillworks/skillworks_userflow.png'
import sw_wireframes from '../assets/skillworks/skillworks_wireframes.jpg'
import sw_bottom_collage from '../assets/skillworks/skillworks_bottom_collage.png'
import sw_bottom_collage_mobile from '../assets/skillworks/skillworks_collage_mobile.png'

import sw_home from '../assets/skillworks/skillworks_home.jpg'
import sw_my_learning from '../assets/skillworks/skillworks_my_learning.jpg'
import sw_my_learning_course from '../assets/skillworks/skillworks_my_learning_course.jpg'
import sw_courses_1 from '../assets/skillworks/skillworks_courses_1.jpg'
import sw_courses_2 from '../assets/skillworks/skillworks_courses_2.jpg'
import sw_library from '../assets/skillworks/skillworks_library.jpg'

import persona_1 from '../assets/drc/persona_1.png'
import persona_2 from '../assets/drc/persona_2.png'
import persona_3 from '../assets/drc/persona_3.png'

import kadyrai_profile_picture from '../assets/skillworks/kadyrai_profile_picture.jpg'

import { VideoAppProject } from '../components/SelectedProjects';

import { useInView } from 'react-intersection-observer';
import PageWithCoverHeaderFooter from '../components/PageWithCoverHeaderFooter';
import NextProject from '../components/NextProject';
import ProjectHeader from '../components/ProjectHeader';
import PageSection from '../components/PageSection';
import PageColumn from '../components/PageColumn';
import FullScreenImage from '../components/FullScreenImage';
import SEO from '../components/SEO';

const ScreenPage = (props) => {
    const { label, about, src, infos, className } = props;
    return (
        <div className={`flex flex-col gap-8 ${className}`}>

            {label ?
                <div className='px-8 md:px-0'>
                    <SectionHeader label={label} />
                </div>
                :
                <div />
            }

            <div className='flex flex-col gap-8 w-full h-full'>

                <div className='flex jjustify-center'>

                    <div className='flex flex-col gap-4 text-drcGray text-base md:w-3/4 md:text-xl px-8 md:px-0'>
                        {about.map((text, index) => (
                            <p key={index}>
                                {text}
                            </p>
                        ))}
                    </div>
                </div>

                <div className='relative'>
                    <img
                        className='w-full h-full select-none md:rounded-md drop-shadow-xl'
                        src={src}
                        alt={`image of screen`}
                    />
                    {infos ? infos.map((info, index) => (
                        <div className='hidden md:block' key={index}>{info}</div>
                    )) : <div></div>}
                </div>
            </div>
        </div>
    )
}

const EmojiReaction = (props) => {
    const { emoji, number, animate = true } = props;
    const [numReactions, setNumReactions] = useState(-1);
    const [pinged, setPinged] = useState(false);

    const getRandomTime = () => {
        return Math.floor(Math.random() * 8000) + 3000;
    };

    const increaseReactions = () => {
        const randomTime = getRandomTime();
        setTimeout(() => {
            setNumReactions(prevNumReactions => {
                if (prevNumReactions < number - 1) {
                    increaseReactions();
                }
                return prevNumReactions + 1;
            });

            setPinged(true);
            setTimeout(() => {
                setPinged(false);
            }, 1000);
        }, randomTime);
    };

    useEffect(() => {
        if (animate && numReactions < number) {
            increaseReactions();
        }
        return () => {
            clearTimeout();
        };
    }, [animate, numReactions, number]);

    const pingClass = pinged ? 'opacity-100' : 'opacity-75';

    return (
        <div
            className={`${numReactions < 1 ? 'hidden' : 'flex'} flex-row items-center transition-all duration-500 rounded-lg text-white text-sm gap-1 px-2 py-0.5 ${pingClass} bg-opacity-75 select-none`}
        >
            <span>{emoji}</span>
            <span>{numReactions}</span>
        </div>
    );
};

const Message1Emojis = [
    <EmojiReaction
        emoji={"🚀"}
        number={"4"}
    />,
    <EmojiReaction
        emoji={"😍"}
        number={"1"}
    />
]

const Message2Emojis = [
    <EmojiReaction
        emoji={"🤮"}
        number={"2"}
    />,
    <EmojiReaction
        emoji={"🤢"}
        number={"3"}
    />,
    <EmojiReaction
        emoji={"😰"}
        number={"1"}
    />
]

const Message3Emojis = [
    <EmojiReaction
        emoji={"👎"}
        number={"1"}
    />,
]

const Message4Emojis = [
    <EmojiReaction
        emoji={"👍"}
        number={"2"}
    />,
]

const Message5Emojis = [
    <EmojiReaction
        emoji={"👍"}
        number={"2"}
    />,
]

const Message6Emojis = [
    <EmojiReaction
        emoji={"🫡"}
        number={"1"}
    />,
]

const Message7Emojis = [
    <EmojiReaction
        emoji={"🚀"}
        number={"4"}
    />,
    <EmojiReaction
        emoji={"👍"}
        number={"2"}
    />,
]

const SectionHeader = (props) => {
    const { label } = props;
    return (
        <div className='flex flex-row gap-6 items-center'>
            <div className="w-2 h-2 rounded-full bg-swBulletBlue flex-none order-0 flex-grow-0"></div>
            <h1 className='text-drcGray text-lg uppercase tracking-widest font-sans'>
                {label}
            </h1>
        </div>
    )
}

const ChatBubbleMessage = (props) => {

    const { ref, inView, entry } = useInView({
        threshold: 0,
    })

    const { profileImg, message, emojis, sender, animationDelayMs = 500 } = props
    const baseDelay = animationDelayMs + 1000
    const emojiDelay = 1000

    return (
        <div ref={ref} className={`flex gap-1.5 items-start ${sender ? 'flex-row' : 'flex-row-reverse'}`}>
            <img
                className={`h-16 w-16 rounded-full ${inView ? `animate-pop-in-from-left duration-1000 opacity-100 delay-[${animationDelayMs}ms]` : 'opacity-0'} border-4 border-gray-400`}
                src={profileImg}
            />
            <div className={`${sender ? 'bg-swChatBlue' : 'bg-swChatGray'} text-white p-4 ${inView ? `animate-pop-in-from-left duration-1000 opacity-100 delay-[${baseDelay}ms]` : 'opacity-0'} text-base md:text-lg mt-4 flex items-center justify-center select-none`}
                style={sender ? { borderRadius: '0px 24px 24px 24px' } : { borderRadius: '24px 0px 24px 24px' }}>
                {message}
            </div>
            {emojis.map((emoji, index) => (
                <div className={`${inView ? `animate-pop-in duration-1000 opacity-100 delay-[${baseDelay + emojiDelay * (index + 1)}ms]` : 'opacity-0'}`} key={index}>
                    {emoji}
                </div>
            ))}
        </div>
    )
}

const ChatBubbleImage = (props) => {
    const { profileImg, image, emojis, sender, animationDelayMs } = props
    const { ref, inView, entry } = useInView({
        threshold: 0,
    })

    const baseDelay = animationDelayMs + 1000
    const emojiDelay = 1000

    return (
        <div ref={ref} className={`flex flex-col md:flex-row gap-1.5 items-start ${sender ? 'flex-row' : 'flex-row-reverse'}`}>
            <img
                className={`h-16 w-16 rounded-full border-4 border-gray-400  ${inView ? `animate-pop-in-from-left duration-1000 opacity-100 delay-[${animationDelayMs}ms]` : 'opacity-0'}`}
                src={profileImg}
            />
            <div className={`${sender ? 'bg-swChatBlue' : 'bg-swChatGray'} text-white p-1 text-lg mt-4 flex items-center justify-center select-none ${inView ? `animate-pop-in-from-left duration-1000 opacity-100 delay-[${baseDelay}ms]` : 'opacity-0'}`}
                style={{ borderRadius: '24px 24px 24px 24px' }}>
                <img
                    src={image}
                />
            </div>
            <div className={`flex flex-row ml-auto md:gap-1 md:ml-0 `}>
                {emojis.map((emoji, index) => (
                    <div className={`${inView ? `animate-pop-in duration-1000 opacity-100 delay-[${baseDelay + emojiDelay * (index + 1)}ms]` : 'opacity-0'}`} key={index}>
                        {emoji}
                    </div>
                ))}
            </div>
        </div>
    )
}

const TopCollage = () => {
    const { ref, inView, entry } = useInView({
        threshold: 0,
    })
    return (
        <div className='flex justify-center'>
            <div ref={ref} className={`flex flex-col-reverse md:flex-row items-center bg-swBlue transition-all gap-8 md:gap-8 pb-8 mb:pb-0 max-w-screen-3xl 4xl:w-3/4 duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <div className='flex flex-col gap-8 text-white text-base md:text-xl font-normal pl-8 md:pl-16'>
                    <span>
                        This case study outlines the implementation of a Learning Management System (LMS) for a
                        fintech company to improve employee learning experiences.
                    </span>
                    <span>
                        It covers customer development, user interviews, insights gained, information architecture, user flows,
                        and addresses the challenge of employee disinterest through an easily accessible course catalog.
                        The study also includes the development process, wireframes, and the final design created using Figma.
                    </span>
                </div>
                <div className={`flex flex-row`}>
                    <img
                        className='hidden md:block 4xl:rounded-lg 4xl:-my-8 4xl:-mx-4 w-full h-full select-none'
                        src={sw_collage}
                        alt={`sw collage`}
                    />
                    <img
                        className='md:hidden rounded w-full h-full select-none'
                        src={sw_collage}
                        alt={`drc image`}
                    />
                </div>
            </div>
        </div>

    )
}

const SkillWorksLogo = () => {

    const [animationType, setAnimationType] = useState(null);

    useEffect(() => {
        const delay = 5000;
        const animationTimer = setInterval(() => {
            const animationTypes = [
                "animate-wiggle-left",
                "animate-jump",
                "animate-wiggle-right",
                "",
                "",
            ]
            const index = Math.round(Math.random() * animationTypes.length) - 1
            setAnimationType(animationTypes[index]);
        }, delay)

        return () => {
            clearInterval(animationTimer);
        }
    }, [])

    return (
        <PageSection>
            <div className='flex flex-col md:flex-row justify-center items-center gap-8 md:gap-8 pt-48 md:pt-48'>
                <img
                    className={`h-32 md:h-48 ${animationType}`}
                    src={logo}
                    alt={"drc logo"}
                />

                <div className='flex flex-col gap-1 text-left'>
                    <span className='text-4xl md:text-8xl font-bold text-white tracking-wide'>
                        SkillWorks
                    </span>
                    <span className='text-2xl md:text-3xl text-swDarkGray'>
                        Transforming Employee Learning
                    </span>
                </div>

            </div>

        </PageSection>
    )
}

const SkillWorks = () => {

    return (
        <PageWithCoverHeaderFooter title={"Educational Platform"} className={"bg-swBlack"}>

            <SEO
                title="Learning Manangement System"
                description="Delve into the redesign of an employee learning platform for a FinTech company."
            />

            <PageColumn>

                <SkillWorksLogo />

                <ProjectHeader
                    description={"I was asked to redesign an existing employee learning platform from scratch for a large FinTech firm. Below I detail my problem solving and design process in producing a modern teaching platform."}
                    about={"SkillWorks is an internal educational platform for swift employee onboarding, knowledge retation and transfer."}
                    timeframe={"2022 | 1 Month"}
                    role={"UX/UI Designer & Researcher"}
                    platforms={"Desktop"}
                    category={"Educational"}
                />

                <TopCollage />

                <div className='flex justify-center'>
                    <div className='flex flex-col gap-8 justify-center md:w-3/4 px-8 md:px-0 md:max-w-screen-2xl'>

                        <div className='flex flex-col gap-8 md:gap-16 justify-center'>
                            <SectionHeader label="Research" />

                            <div className='text-white text-xl md:text-2xl font-bold'>
                                Customer Development
                            </div>
                        </div>

                        <div className='flex flex-col xl:flex-row gap-16 items-center'>
                            <div className='flex flex-col gap-4 items-center xl:w-3/4'>
                                <div className='text-drcGray text-base md:text-xl'>
                                    For insights into the employee learning landscape and gather feedback, customer development activities were conducted, followed by user interviews. These interviews involved a diverse group of employees from various departments and hierarchical levels.
                                </div>

                                <div className='text-drcGray text-base md:text-xl'>
                                    The feedback revealed widespread dissatisfaction among users, prompting the company's desire to enhance employee motivation and engagement with the platform.
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div className='flex justify-center'>
                    <div className='flex flex-col gap-8 md:w-3/4 px-8 md:px-0 max-w-screen-2xl'>

                        <ChatBubbleMessage
                            profileImg={kadyrai_profile_picture}
                            message={"Hey guys, I'm re-designing the LMS platform from scratch. Please tell me about any issues? Below is the old design."}
                            emojis={Message1Emojis}
                            sender={true}
                            animationDelayMs={1000}
                        />

                        <ChatBubbleImage
                            profileImg={kadyrai_profile_picture}
                            image={sw_old_design}
                            emojis={Message2Emojis}
                            sender={true}
                        />

                        <div className='grid grid-cols-1 md:grid-cols-2 4xl:grid-cols-4  gap-8'>
                            <ChatBubbleMessage
                                profileImg={persona_1}
                                message={"It just really sucks..."}
                                emojis={Message3Emojis}
                                sender={false}
                                animationDelayMs={4000}
                            />
                            <ChatBubbleMessage
                                profileImg={persona_2}
                                message={"I don't know how to check my previous courses, everything is messy, and hard to navigate"}
                                emojis={Message4Emojis}
                                sender={false}
                                animationDelayMs={7000}
                            />
                            <ChatBubbleMessage
                                profileImg={persona_3}
                                message={"Starting a course requires too many steps, too complex"}
                                emojis={Message5Emojis}
                                sender={false}
                                animationDelayMs={9000}
                            />
                            <ChatBubbleMessage
                                profileImg={persona_1}
                                message={"I mean where to even start: I can't navigate through the platform, can't find what I actually need, design looks ancient!"}
                                emojis={Message6Emojis}
                                sender={false}
                                animationDelayMs={12000}
                            />
                        </div>

                        <ChatBubbleMessage
                            profileImg={kadyrai_profile_picture}
                            message={"Gotcha guys!"}
                            emojis={Message7Emojis}
                            sender={true}
                            animationDelayMs={3500}
                        />

                    </div>
                </div>

                <div className='flex justify-center'>
                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-8 px-8 md:px-0 md:w-3/4 max-w-screen-2xl"}>

                        <div className='md:col-start-1 md:col-end-2 flex flex-col gap-4'>
                            <h3 className='text-xl md:text-2xl text-white font-bold'>
                                Problem
                            </h3>
                            <div className='flex flex-col gap-4 text-base md:text-xl text-swLightGray'>
                                <div className='flex flex-row items-center gap-4'>
                                    <div className='bg-swBulletBlue h-1.5 w-1.5 p-1 rounded-full' />
                                    <p>
                                        Employees stressed a lack of motivation and interest in the existing courses offered by the LMS.
                                    </p>
                                </div>
                                <div className='flex flex-row items-center gap-4'>
                                    <div className='bg-swBulletBlue h-1.5 w-1.5 p-1 rounded-full' />
                                    <p>
                                        The previous LMS design was considered bad and confusing, resulting in employees being hesitant to use the platform.
                                    </p>
                                </div>
                                <div className='flex flex-row items-center gap-4'>
                                    <div className='bg-swBulletBlue h-1.5 w-1 p-1 rounded-full' />
                                    <p>
                                        Employees faced challenges in understanding how to navigate and effectively utilize the features of the LMS.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='md:col-start-2 md:col-end-3 flex flex-col gap-4'>
                            <h3 className='text-xl md:text-2xl text-white font-bold'>
                                Goals
                            </h3>
                            <div className='flex flex-col gap-4 text-base md:text-xl text-swLightGray'>
                                <div className='flex flex-row items-center gap-4'>
                                    <div className='bg-swBulletBlue h-1.5 w-1.5 p-1 rounded-full' />
                                    <p>
                                        Understand the needs and pain points of employees regarding learning opportunities.
                                    </p>
                                </div>
                                <div className='flex flex-row items-center gap-4'>
                                    <div className='bg-swBulletBlue h-1.5 w-1.5 p-1 rounded-full' />
                                    <p>
                                        Enhance the overall learning experience by addressing the identified challenges and pain points.
                                    </p>
                                </div>
                                <div className='flex flex-row items-center gap-4'>
                                    <div className='bg-swBulletBlue h-1.5 w-1 p-1 rounded-full' />
                                    <p>
                                        Improve employee engagement and interest in courses offered through the LMS
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='flex justify-center'>
                    <div className='flex flex-col gap-8 md:gap-16 md:w-3/4 px-8 md:px-0 max-w-screen-2xl'>
                        <div className='flex flex-col gap-6'>
                            <h2 className='text-white text-xl md:text-3xl font-bold'>
                                Information Architecture
                            </h2>
                            <p className='text-drcGray text-base md:text-xl font-normal md:w-2/3'>
                                The insights gained from user interviews guided the development of an information architecture that would address the identified challenges and enhance the overall user experience.
                            </p>
                        </div>
                        <img
                            src={sw_information_architecture}
                        />
                    </div>
                </div>

                <div className='flex justify-center'>
                    <div className='flex flex-col items-center md:flex-row gap-8 md:gap-8 md:w-3/4 px-8 md:px-0 max-w-screen-2xl'>
                        <div className='flex flex-col gap-6 md:w-1/2'>
                            <h2 className='text-white text-xl md:text-3xl font-bold'>
                                User-Flows with Multiple Scenarios
                            </h2>
                            <p className='text-drcGray text-base md:text-xl font-normal'>
                                User-flows were developed to define the various scenarios and interactions within the LMS. This process involved mapping out the journey of employees from course discovery to enrollment and completion, helping to identify potential pain points and areas for improvement.
                            </p>
                        </div>
                        <img
                            className='md:w-1/2'
                            src={sw_user_flows}
                        />
                    </div>
                </div>

                <div className='flex justify-center'>
                    <div className='flex flex-col gap-8 md:gap-12  md:w-3/4 px-8 md:px-0 max-w-screen-2xl'>
                        <SectionHeader label={"UI Design"} />
                        <div className='flex flex-col gap-8'>
                            <h2 className='text-white text-2xl font-bold'>
                                Wireframes
                            </h2>
                            <p className='text-drcGray text-base md:text-xl font-normal md:w-2/3'>
                                Lo-Fi wireframes were created to illustrate the visual representation and interaction design of the redesigned LMS.
                            </p>
                        </div>
                        <img src={sw_wireframes} />
                    </div>
                </div>

                <div className='flex justify-center'>
                    <div className='flex flex-col gap-8 md:gap-32 md:w-3/4 px-8 md:px-0 max-w-screen-2xl'>
                        <div className='flex flex-col gap-8'>
                            <h2 className='text-white text-2xl font-bold'>
                                Final Designs
                            </h2>
                            <p className='text-drcGray text-base md:text-xl font-normal md:w-2/3'>
                                The LMS project was implemented based on the wireframes. The easy-to-use course catalog feature was integrated into the system, and the redesigned LMS interface was launched. Post-implementation, employee engagement and interest in learning courses significantly improved, with employees finding the platform more appealing, user-friendly, and easy to navigate.
                            </p>
                        </div>
                    </div>
                </div>

                <FullScreenImage
                    className={"bg-swBlue"}
                    src={sw_bottom_collage}
                    srcMobile={sw_bottom_collage_mobile}
                />

                <div className='flex justify-center'>

                    <div className='flex flex-col gap-8 md:gap-32 md:w-3/4 max-w-screen-2xl'>

                        <ScreenPage
                            label="Home"
                            about={["Streamlined for easy navigation, personalized recommendations and current course monitoring."
                            ]}
                            src={sw_home}
                        // infos={}
                        />

                        <ScreenPage
                            label="My Learning"
                            about={["Comprehensive overview of course journeys. The simplified interface ensures you stay in control, effortlessly managing and tracking your learning progress."
                            ]}
                            src={sw_my_learning}
                        // infos={}
                        />

                        <ScreenPage
                            className={"-my-8 md:-my-32 pb-8 md:pb-16"}
                            label={null}
                            about={["Explore course pages designed for simplicity and information. Easily access comprehensive details to kickstart your learning journey."
                            ]}
                            src={sw_my_learning_course}
                        // infos={}
                        />

                        <ScreenPage
                            label="Courses"
                            about={["Easy navigation with user-friendly filters, ensuring an intuitive experience tailored to your learning preferences."
                            ]}
                            src={sw_courses_1}
                        // infos={}
                        />

                        <ScreenPage
                            className={"-my-8 md:-my-32 pb-8 md:pb-16"}
                            label={null}
                            about={["When you're ready to gauge your understanding, seamlessly transition to the testing page for a self-paced evaluation of your knowledge."
                            ]}
                            src={sw_courses_2}
                        // infos={}
                        />

                        <ScreenPage
                            label={"Library"}
                            about={["Dive into the company-provided treasure trove in a library section. Here, employees can seamlessly download enriching books, fostering a culture of continuous learning and development."
                            ]}
                            src={sw_library}
                        // infos={}
                        />

                    </div>
                </div>

                <div className='flex justify-center'>
                    <div className='flex flex-col gap-8 md:gap-32 md:w-3/4 px-8 md:px-0 max-w-screen-2xl'>
                        <div className='flex flex-col gap-6'>
                            <span className='text-white text-xl md:text-2xl font-bold'>
                                Key Takeaways
                            </span>
                            <div className='flex flex-col gap-4 text-drcGray text-base md:text-xl font-normal md:w-2/3'>
                                <p>
                                    I've had a bit of an eye-opener: user interviews are actually amazing! It's surprising how much I've learned about my colleagues and users—insights I never knew I was missing. Their perspectives were incredibly valuable and hinted at small details and usage patterns I had no idea about.
                                </p>
                                <p>
                                    Another valuable lesson I learnt was the power of simplification. Taking our complex designs and breaking them down into smaller, more manageable parts made such a difference. It also streamlined our end-to-end development process and made tackling bugs much less of a hassle.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <NextProject>
                    <VideoAppProject />
                </NextProject>

            </PageColumn>
        </PageWithCoverHeaderFooter>
    )
}

export default SkillWorks;