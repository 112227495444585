import { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";

import { BlurrySphere, MenuButton } from '../components';
import { isMobile } from 'react-device-detect';
import { drc_preview, skillworks_preview, ski_preview, turandot_preview, video_app_preview } from '../assets';

const ProjectThumbnail = ({link, title, subtitle, src, onClick, comingSoon = false}) => {

    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);

    const handleImageLoad = (event, size) => {
        const ratio = event.target.naturalHeight / event.target.naturalWidth;
        const height = `calc(${size}vw * ${ratio})`;
        const width = `calc(${size}vw * ${1/ratio} * 1/2)`;
        setImageHeight(height);
        setImageWidth(width);
    };
    
    const Content = () => (
        <div className='flex flex-col gap-4 group'>
            <div className="relative p-8 overflow-hidden rounded-xl" style={{ width: imageWidth, height: imageHeight }}>
                <img
                    className={`absolute inset-0 object-cover w-full h-full transform transition-all duration-1000 ${comingSoon ? 'opacity-50' : 'md:opacity-50 opacity-100 cursor-pointer group-hover:opacity-100 group-hover:scale-105'}`}
                    src={src} 
                    alt={`${title} image`}
                    onLoad={(event) => handleImageLoad(event, isMobile ? 80 : 30)}
                >
                </img>
                <div className={`${comingSoon ? 'absolute' : 'hidden' } top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-darkblack rounded-lg bg-opacity-75 p-1 md:p-4`}>
                    <span className='text-lg md:text-2xl font-bold text-white shadow-black drop-shadow-lg select-none'>
                        Coming Soon
                    </span>
                </div>
            </div>
            <div className='flex flex-col gap-0 justify-start'>
                <div className='text-white text-base font-bold'>
                    {title}
                </div>
                <div className='text-gray-300 text-sm'>
                    {subtitle}
                </div>
            </div>
        </div>
    )

    return (
        link ? 
        <Link to={link} onClick={onClick} >
            <Content />
        </Link>
            :
        <Content />
    )
}

const Menu = () => {

    const [ isOpen, setIsOpen] = useState(false);
    const [ transition, setTransition ] = useState(false);
    const [ transitionToBg, setTransitionToBg ] = useState(false);
    const [top, setTop] = useState(0);

    useEffect(() => {
        // const timer = setTimeout(() => {
        //     setTransition(true);
        // }, 1000);
        // const timerBg = setTimeout(() => {
        //     setTransitionToBg(true);
        // }, 0);
    
      return () => { 
        // clearTimeout(timer); 
        // clearTimeout(timerBg); 
    }
    }, [])

    const toggleOpen = () => {
        if (isOpen) {
            const timer = setTimeout(() => {
                setTransition(false);
            }, 500);
            const timerBg = setTimeout(() => {
                setTransitionToBg(false);
                document.body.classList.remove('overflow-hidden');
            }, 0);
            // setTimeout(() => {
            //     window.scrollTo({
            //         top: top,
            //         behavior: 'auto'
            //     })
            // }, 0)
        } else {

            const timer = setTimeout(() => {
                setTransition(true);
            }, 500);
            const timerBg = setTimeout(() => {
                setTransitionToBg(true);
            }, 0);

          document.body.classList.add('overflow-hidden');
        //   setTop(window.scrollY);

        //   setTimeout(() => {
        //     window.scrollTo({
        //         top: 0,
        //         behavior: 'auto'
        //     }, 1500);
        //   })
        }
        setIsOpen(!isOpen);
    };

    const divRef = useRef(null);

    const style1 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-20 filter blur-3xl";
    const style2 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-30 filter blur-3xl";
    const style3 = "bg-gradient-to-r from-blueParticle via-white to-blueParticle opacity-30 filter blur-lg";

    const sizeCSS1 = 196;
    const sizeCSS2 = 96;
    const sizeCSS3 = 8;
    
    return (
        <div className={`relative ${isOpen ? 'z-50' : ''}`}>

            <div className="fixed top-10 right-8 z-50">
                <button className="flex" 
                        type="button" 
                        onClick={toggleOpen}
                >
                <MenuButton isOpen={!isOpen} animate={true} />
                </button>
            </div>

            <div className={`bg-darkblack fixed h-screen w-screen transition-opacity duration-500 ${transitionToBg && isOpen && !transition ? 'opacity-100' : 'opacity-0 collapse'}`}>

            </div>

            <div className={`bg-darkblack fixed h-screen w-screen ${transition && isOpen ?'opacity-100' : 'transition-opacity duration-500 opacity-0 collapse'}`}>

                <div className={`opacity-0 top-0 left-0 w-screen h-screen transition-all duration-1000 font-gilroy ${transition && isOpen ? 'opacity-100': 'opacity-0'}`}>

                    <div ref={divRef} className='w-screen h-screen'>

                        <div className='hidden md:block'>
                            <BlurrySphere style={`h-196 w-196 ${style1}`} sizeCSS={sizeCSS1} doAnimate={true} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={111} positionOffset={{x: 256, y: -128}} />

                            <BlurrySphere style={style2} sizeCSS={sizeCSS2} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={112} positionOffset={{x: 296, y: -196}} />
                            <BlurrySphere style={`h-8 w-8 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} doAnimate={true} angleOffset={30} seed={204} positionOffset={{x: 144, y: 144}} />
                            <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} doAnimate={true} angleOffset={0} seed={205} positionOffset={{x: -16, y: 96}} />
                            <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} doAnimate={true} angleOffset={60} seed={208} positionOffset={{x: 32, y: 96}} />
                        </div>

                        <div className='block md:hidden'>
                            <BlurrySphere style={`h-64 w-64 ${style1}`} sizeCSS={sizeCSS1} doAnimate={false} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={111} positionOffset={{x: 64, y: -128}} />

                            <BlurrySphere style={style2} sizeCSS={sizeCSS2} containingDivRef={divRef} doAnimate={false} followsMouse={false} angleOffset={0} seed={112} positionOffset={{x: 296, y: 32}} />
                            {/* <BlurrySphere style={`h-4 w-4 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} doAnimate={true} angleOffset={0} seed={204} positionOffset={{x: 0, y: 0}} /> */}
                            {/* <BlurrySphere style={`h-4 w-4 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} doAnimate={true} angleOffset={0} seed={205} positionOffset={{x: -16, y: 0}} /> */}
                            {/* <BlurrySphere style={`h-4 w-4 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} doAnimate={true} angleOffset={0} seed={206} positionOffset={{x: 32, y: 0}} /> */}
                        </div>

                        <div className={`fixed top-32 right-8 md:right-16 transition-all ease-in-out duration-500 ${transition && isOpen ? 'translate-y-0': 'translate-y-8'}`}>

                            <div className='flex flex-row gap-2'>

                                <div className={`flex flex-col gap-4 uppercase text-gray-500 text-4xl md:text-5xl text-right `}>
                                    <Link to="/">
                                        <span className="transition-all duration-500 hover:text-white" onClick={toggleOpen}>
                                            Home
                                        </span>
                                    </Link>
                                    <Link to="/about">
                                        <span className="transition-all duration-500 hover:text-white" onClick={toggleOpen}>
                                            About
                                        </span>
                                    </Link>
                                    {/* <Link to="/blog">
                                        <span className="transition-all duration-500 hover:text-white" onClick={toggleOpen}>
                                            Blog
                                        </span>
                                    </Link> */}
                                    <Link to="/about/resume" target="_blank">
                                        <span className="transition-all duration-500 hover:text-white" onClick={toggleOpen}>
                                            Resumé
                                        </span>
                                    </Link>
                                </div>

                                <div className='bg-gray-700 w-px'>
                                    <div className='flex-grow'></div>
                                </div>

                            </div>
                        </div>
                    
                        <div className='fixed bottom-8 md:bottom-8'>

                            <div className={`pl-8 w-screen transition-all ease-in-out duration-500 ${transition && isOpen ? 'translate-y-0' : 'translate-y-16'}`}>
                                <div className='flex flex-col gap-4'>
                                    <div className='text-2xl text-gray-500 font-bold'>
                                        Selected Projects
                                    </div>
                                    <div className='flex flex-row gap-4 md:gap-12 overflow-x-auto'>
                                        <ProjectThumbnail 
                                            link={'/project/videocodec'} 
                                            title={"Video Codec"} 
                                            subtitle={"Video Conferencing."} 
                                            src={video_app_preview} 
                                            comingSoon={false} 
                                            onClick={toggleOpen}
                                        />
                                        <ProjectThumbnail 
                                            link={'/project/drc'}
                                            title={"DRC"} 
                                            subtitle={"Cloud Computing Platform."} 
                                            src={drc_preview} 
                                            onClick={toggleOpen}
                                        />
                                        <ProjectThumbnail 
                                            link={'/project/skillworks'}
                                            title={"SkillWorks"} 
                                            subtitle={"Learning Management System (LMS)."} 
                                            src={skillworks_preview} 
                                            onClick={toggleOpen}
                                        />
                                        <ProjectThumbnail 
                                            link={null}
                                            title={"Shymbulak"} 
                                            subtitle={"Skiresort Management."} 
                                            src={ski_preview} 
                                            comingSoon={true} 
                                            onClick={toggleOpen}
                                        />
                                        <ProjectThumbnail 
                                            link={null}
                                            title={"Turandot"} 
                                            subtitle={"Exquisite Asian Cuisine."} 
                                            src={turandot_preview} 
                                            comingSoon={true} 
                                            onClick={toggleOpen}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default Menu;