
import React, { useState, useRef, useEffect } from 'react';

const Typewriter = ({ className, textArray, initialDelay = 0, characterDelay = 33 }) => {

    const [textArrayIndex, setTextArrayIndex] = useState(0)
    const [textIndex, setTextIndex] = useState({ current: 0 });
    const [forwardDirection, setForwardDirection] = useState(true);
    const [waiting, setWaiting] = useState(false);
    const [characters, setCharacters] = useState([]);

    useEffect(() => {
        setCharacters(
            textArray[textArrayIndex].split("").map((char, index) => ({
                char,
                index: index,
                isVisible: textIndex.current > index,
            }))
        );
    }, [textIndex, textArrayIndex, textArray]);

    useEffect(() => {
        if (waiting) { return; }

        if (forwardDirection && textIndex.current <= textArray[textArrayIndex].length) {
            setTimeout(() => {
                setTextIndex({ current: (textIndex.current + 1) });
            }, characterDelay);
        } else if (!forwardDirection && textIndex.current >= 0) {
            setTimeout(() => {
                setTextIndex({ current: (textIndex.current - 1) });
            }, characterDelay);
        } else if (textIndex.current <= 0) {
            setWaiting(true);
            setTimeout(() => {
                setTextArrayIndex((textArrayIndex + 1) % textArray.length);
                setForwardDirection(!forwardDirection);
                setWaiting(false);
            }, 1500);
        }
        else {
            let waitTime = textIndex.current > 0 ? 1500 : 100;
            setWaiting(true);
            setTimeout(() => {
                setWaiting(false);
                setForwardDirection(!forwardDirection);
            }, waitTime);
        }
        return (() => {

        });
    }, [textArray, textArrayIndex, textIndex, forwardDirection, waiting, characterDelay]);

    const charVisibilityState = (char) => {
        if (char.isVisible && forwardDirection) {
            return 'animate-fade-in-blur'
        } else if (!char.isVisible && forwardDirection) {
            return 'opacity-0';
        } else if (char.isVisible && !forwardDirection) {
            return 'opacity-100';
        } else {
            return 'animate-fade-out-blur'
        }
    };

    return (
        <h2>
            <div className={`${className}`}>
                {characters.map((char, idx) => (
                    <span key={idx} className={`${charVisibilityState(char)} duration-500 transition-all`}>
                        {char.char}
                    </span>
                ))}
            </div>
        </h2>
    )
};

export default Typewriter;