import React, { useEffect, useRef, useState } from 'react';

import { BsDot } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import BlurrySphere from './BlurrySphere';
import CTAButton from './CTAButton';
import HorizontalSeparator from './Separator';
import GradientBorder from './GradientBorder';

const Field = (props) => {
    const { title, subtitle, role, date, link = null } = props;

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex flex-col gap-0'>
                <h3 className='text-white text-xl md:text-2xl font-bold tracking-wide'>
                    {link !== null ?
                        (<Link to={link} target="_blank" className="text-pinkDesignerBright hover:text-white transition-all duration-500">
                            {title}
                        </Link>)
                        :
                        title
                    }
                </h3>
                <h4 className='text-gray-300 text-xl md:text-2xl'>
                {subtitle}
                </h4>
            </div>
            <div className='flex flex-row items-center text-gray-500 text-base md:text-lg gap-1 md:gap-4'>
                {role} <BsDot /> {date}
            </div>
        </div>
    )
}

const Table = ({ title, fields }) => {

    return (
        <div className='flex'>
            <div className='flex h-full w-full flex-col rounded-lg gap-4 p-1 bg-darkblack'>
                <p className='text-white uppercase text-2xl md:text-3xl font-normal tracking-wide pb-2 md:pb-8'>
                    {title}
                </p>
                {fields.map((field, index) => (
                    <div key={index}>
                        {field}
                    </div>
                ))}
            </div>
        </div>
    );
};

const CVSummary = () => {

    const divRef = useRef(null);

    const style1 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-20 filter blur-lg";
    const style2 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-30 filter blur-3xl";
    const style3 = "bg-gradient-to-r from-blueParticle via-white to-blueParticle opacity-20 filter blur-md";

    const sizeCSS1 = 64;
    const sizeCSS2 = 96;
    const sizeCSS3 = 8;

    return (
        <div className='flex'>

            <div className='flex flex-col gap-8 md:gap-16 md:w-3/4 md:max-w-screen-2xl'>
                <div className='flex flex-col gap-16 font-gilroy md:flex-row justify-between' ref={divRef}>

                    <div className='md:w-1/2'>
                        <Table title={"Work Experience"} fields={[
                            <Field title={"Yoko Space"} subtitle={"Development Studio"} role={"Lead Product Designer"} date={"Feb 2023 — Present"} link={"https://www.yoko.space"} />,
                            <Field title={"Freedom Finance"} subtitle={"Fintech & Banking"} role={"UX/UI Designer"} date={"Aug 2021 — Feb 2023"} />,
                        ]} />
                    </div>
                    <div className='md:w-1/2'>
                        <Table title={"Select Projects"} fields={[
                            <Field title={"AI Video Codec"} subtitle={"Freelance | Deep Render"} role={"Product Designer"} date={"Sept 2023"} link={"/project/videocodec"} />,
                            <Field title={"Skiresort Management System"} subtitle={"Yoko Space"} role={"Product Designer"} date={"Sept 2023"} link={null} />,
                            <Field title={"Restaurant App"} subtitle={"Turandot"} role={"UX/UI Designer & Researcher"} date={"Aug 2023"} />,
                            <Field title={"AI Cloud Platform"} subtitle={"Freelance | Deep Render"} role={"Product Designer"} date={"June 2023 - Aug 2023"} link={"/project/drc"} />,
                            <Field title={"SkillWorks"} subtitle={"Freedom Finance"} role={"UX/UI Designer"} date={"April 2022"} link={"/project/skillworks"} />,
                            <Field title={"Petrol & Gas Management System"} subtitle={"Freelance | Yoko Space"} role={"UI Designer"} date={"2022 | 3 Months"} />,
                        ]} />
                    </div>

                </div>

            </div>
        </div>
    );
}

export default CVSummary;