
const HorizontalSeparator = (props) => {
    const { className } = props
    return (
        <div className="flex justify-center">
            <hr className={`my-6 border-gray-700 w-full h-1 mmx-16 mmd:mx-64 ${className}`}/>
        </div>
    )
}

export default HorizontalSeparator;