
import { useState, useEffect, useRef } from 'react';

import mars from '../assets/drc/mars.png'
import logo from '../assets/drc/drc_logo.svg'
import title_mobile from '../assets/drc/drc_title_mobile.svg'
import title from '../assets/drc/drc_title.svg'
import drc_collage from '../assets/drc/drc_collage.png'
import drc_collage_mobile from '../assets/drc/drc_collage_mobile.png'
import architecture from '../assets/drc/architecture.png'

import drc_vessels from '../assets/drc/vessels.png'
import drc_vessel_page from '../assets/drc/vessel_page.png'
import drc_add_vessel from '../assets/drc/add_vessels.png'
import drc_filters from '../assets/drc/filters.png'
import drc_experiments from '../assets/drc/experiments.png'
import drc_hardware from '../assets/drc/hardware.png'
import drc_my_profile from '../assets/drc/my_profile.png'

import persona_1 from '../assets/drc/persona_1.png'
import persona_2 from '../assets/drc/persona_2.png'
import persona_3 from '../assets/drc/persona_3.png'
import requirements from '../assets/drc/requirements.png'
import thank_you from '../assets/drc/thank_you.png'

import wireframe_1 from '../assets/drc/wireframe_1.png'
import wireframe_2 from '../assets/drc/wireframe_2.png'
import wireframe_3 from '../assets/drc/wireframe_3.png'
import wireframe_4 from '../assets/drc/wireframe_4.png'

import drc_collage_screens from '../assets/drc/drc_collage_screens.png'
import drc_terminal from '../assets/drc/drc_terminal.png'

import { SkillWorksProject } from '../components/SelectedProjects'
import { isMobile } from 'react-device-detect';
import PageWithCoverHeaderFooter from '../components/PageWithCoverHeaderFooter';
import PageSection from '../components/PageSection';
import PageColumn from '../components/PageColumn';
import ProjectHeader from '../components/ProjectHeader';
import FullScreenImage from '../components/FullScreenImage';
import NextProject from '../components/NextProject';
import SEO from '../components/SEO';
import { pushCustomEventToClarity } from '../components/Clarity';

const VesselInfoPulse = (props) => {
    const { label, about } = props
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        pushCustomEventToClarity("drc | vessel-info-hover | " + label);
        setIsHovered(true);
    }

    const handleMouseLeave = () => {
        setIsHovered(false);
    }

    return (
        <div className='flex flex-col gap-2'>
            <div className={`flex flex-col bg-drcDarkGray gap-2 rounded-xl p-4 z-30 ease-in-out duration-500 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
                <h4 className='text-drcLightGray text-xl font-semibold'> {label} </h4>
                <p className='text-drcGray text-sm font-normal'> {about} </p>
            </div>

            <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className={`absolute animate-ping top-1/2 left-1/2 z-10 ease-in-out duration-500 ${isHovered ? 'opacity-0' : 'opacity-100'}`}>
                    <div className="w-6 h-6 bg-drcGreen rounded-full z-20"></div>
                </div>
                <div className="absolute top-1/2 left-1/2 z-20 hover:opacity-80 ease-in-out duration-500">
                    <div className="w-6 h-6 bg-drcGreen rounded-full z-10"></div>
                </div>
            </div>

        </div>
    )
}

const VesselPage = (props) => {
    const { label, about, src, infos } = props;
    return (
        <div className='flex flex-col gap-8'>

            <div>
                <SectionHeader label={label} />
            </div>

            <div className='flex flex-col gap-8 w-full h-full'>

                <div className='flex'>

                    <div className='flex flex-col gap-4 text-drcGray text-base md:text-xl md:w-3/4'>
                        {about.map((text, index) => (
                            <p key={index}>
                                {text}
                            </p>
                        ))}
                    </div>
                </div>

                <div className='relative'>
                    <img
                        className='w-full h-full select-none md:rounded-md drop-shadow-xl'
                        src={src}
                        alt={`drc color palette`}
                    />
                    {infos ? infos.map((info, index) => (
                        <div className='hidden md:block' key={index}>{info}</div>
                    )) : <div></div>}
                </div>
            </div>
        </div>
    )
}

const VesselsInfo = [
    <div className='absolute top-8 right-8 max-w-4xl'>
        <VesselInfoPulse
            label={"Notifications"}
            about={"Events on the platform are posted here."}
        />
    </div>,
    <div className='absolute top-1/3 right-2/3 max-w-4xl'>
        <VesselInfoPulse
            label={"Table"}
            about={"Vessels are listed based on search and filtering terms."}
        />
    </div>,
    <div className='absolute -top-8 left-1/2 max-w-4xl'>
        <VesselInfoPulse
            label={"Create Vessel"}
            about={
                <span>
                    <span className='text-xl text-white font-bold'>+</span> opens modal creation window.
                </span>
            }
        />
    </div>
];

const VesselExperimentInfo = [
    <div className='absolute top-8 left-1/2 max-w-4xl'>
        <VesselInfoPulse
            label={"State"}
            about={
                <span>
                    <span className='text-drcGray font-bold'>State</span> informs user of experiment status.
                </span>
            }
        />
    </div>,
    <div className='absolute top-1/2 left-2/3 max-w-4xl'>
        <VesselInfoPulse
            label={"Progress"}
            about={
                <span>
                    <span className='text-drcGray font-bold'>Iteration</span> indicates training progress.
                </span>
            }
        />
    </div>
];

const VesselPageInfo = [
    <div className='absolute top-32 right-2/4 max-w-4xl'>
        <VesselInfoPulse
            label={"About"}
            about={
                <span>
                    General information about the container, eg it is <span className='font-semibold text-drcGreen'>running</span>
                </span>
            }
        />
    </div>,
    <div className='absolute top-64 left-1/2 max-w-4xl'>
        <VesselInfoPulse
            label={"SSH"}
            about={
                <span>
                    Allows researcher to access the vessel via <span className='font-semibold'>SSH</span>
                </span>
            }
        />
    </div>,
    <div className='absolute top-96 right-2/4 max-w-4xl'>
        <VesselInfoPulse
            label={"Hardware Usage"}
            about={"Overview of the GPU and CPU usage of the vessel"}
        />
    </div>,
    <div className='absolute bottom-64 left-2/4 max-w-4xl'>
        <VesselInfoPulse
            label={"Experiments"}
            about={
                <span>
                    Overview of experiments within <span className='font-semibold'>this</span> vessel
                </span>
            }
        />
    </div>,

];

const VesselMyProfileInfo = [
    <div className='absolute top-32 right-1/4 max-w-4xl'>
        <VesselInfoPulse
            label={"SSH Keys"}
            about={
                <span>
                    The user is permitted <span className='font-semibold'>SSH</span> vessel access with the following keys
                </span>
            }
        />
    </div>,
]

const PersonaHeader = (props) => {
    const { pictureSrc, nameTitle, jobTitle, location } = props
    return (
        <div>

            <div className='flex flex-col md:flex-row gap-10 justify-start items-center'>
                <div className="w-40 h-40">
                    <img
                        className='select-none'
                        src={pictureSrc}
                        alt={`persona picture`}
                    />
                </div>
                <div className='flex flex-col gap-1'>
                    <h1 className='text-drcLightGray text-xl md:text-4xl font-semibold'>
                        {nameTitle}
                    </h1>
                    <h2 className='text-drcGray text-base md:text-2xl font-normal'>
                        {jobTitle}
                    </h2>
                    <h3 className='text-drcGray text-sm md:text-xl font-extralight'>
                        {location}
                    </h3>
                </div>

            </div>

        </div>
    )
}

const PersonaNeedsAndGoals = (props) => {
    const { needs, painPoints } = props
    return (
        <div className='flex flex-col md:flex-row gap-10 justify-between'>
            <div className='flex flex-col gap-4 md:w-1/2'>
                <span className='text-drcLightGray text-xl md:text-2xl font-semibold'>
                    Needs & Goals
                </span>
                <ol className='flex flex-col gap-4'>
                    {needs.map((need, index) => (
                        <div key={index} className='flex flex-row gap-4 text-base md:text-xl text-drcGray'>
                            <span> {index + 1}.</span>
                            <span> {need} </span>
                        </div>
                    ))}
                </ol>
            </div>
            <div className='flex flex-col gap-4 md:w-1/2'>
                <span className='text-drcLightGray text-xl md:text-2xl font-semibold'>
                    Pain Points
                </span>
                <ol className='flex flex-col gap-4'>
                    {painPoints.map((painPoint, index) => (
                        <li key={index} className='text-base md:text-xl text-drcGray'>
                            <div className='flex flex-row gap-4 text-base md:text-xl text-drcGray'>
                                <span> {index + 1}.</span>
                                <span> {painPoint} </span>
                            </div>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    )
}

const PersonaSection = (props) => {
    const { about, pictureSrc, nameTitle, jobTitle, location, needs, painPoints } = props
    return (
        <div className='flex flex-col gap-10'>

            <PersonaHeader
                pictureSrc={pictureSrc}
                nameTitle={nameTitle}
                jobTitle={jobTitle}
                location={location}
            />

            <div className='flex flex-col gap-4'>
                <h4 className='text-drcLightGray text-xl md:text-2xl font-semibold'>
                    About
                </h4>
                <span className='text-drcGray text-base md:text-xl flex flex-col gap-4'>
                    {about.map((paragraph, index) => (
                        <p key={index}>
                            {paragraph}
                        </p>
                    ))}
                </span>
            </div>

            <PersonaNeedsAndGoals
                needs={needs}
                painPoints={painPoints}
            />

        </div>
    )
}

const SectionHeader = ({ label, className }) => {
    return (
        <div className={`flex flex-row gap-6 items-center ${className}`}>
            <div className="w-2 h-2 rounded-full bg-drcGreen flex-none order-0 flex-grow-0"></div>
            <h2 className='text-drcGray text-lg uppercase tracking-widest font-sans'>
                {label}
            </h2>
        </div>
    )
}

const CheckeredBackground = () => {
    return (
        <div className="absolute inset-0 4xl:w-2/3">
            <div className="relative h-full 4xl:left-1/4 4xl:right-1/4">
                <div className="h-full bg-[length:350px_350px] opacity-15 bg-[url('./assets/drc/drc_tiles.png')] bg-center bg-repeat bg-blend-screen"> </div>
                <div className="absolute inset-0">
                    <div
                        className="absolute inset-0"
                        style={{
                            background: 'linear-gradient(to bottom, transparent, rgba(21, 21, 21, 1.0))',
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

const Mars = () => {

    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setRotation((prevRotation) => prevRotation + 1.0 / 30);
        }, 1000 / 30);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='bottom-0 flex items-end md:flex md:flex-row md:justify-center'>
            <div className="relative">
                <div className=''>
                    <img
                        className="filter brightness-50 opacity-60 object-contain md:mx-auto"
                        src={mars}
                        alt="drc image"
                        style={{ filter: 'drop-shadow(16px 16px 64px rgba(255, 255, 255, 0.1))', transform: `${isMobile ? '' : `rotate(${rotation}deg`}` }}
                    />
                </div>
            </div>
        </div>
    )

}

const DRC = () => {

    return (
        <PageWithCoverHeaderFooter title={"Cloud Platform"} className={"bg-darkblack"}>

            <SEO
                title="Cloud Computing Platform"
                description="Explore my journey of designing a novel cloud computing platform for a machine learning start-up."
            />

            <PageSection>
                <CheckeredBackground />
            </PageSection>

            <PageColumn>

                <div className='flex justify-center'>
                    <div className='flex flex-col md:flex-row justify-evenly items-center gap-8 md:gap-64 pt-16 md:pt-24 md:w-3/4 max-w-screen-2xl'>

                        <img
                            className='h-48 md:h-64'
                            src={logo}
                            alt={"drc logo"}
                        />

                        {/* Mobile title  */}
                        <img
                            className='md:hidden h-48'
                            src={title_mobile}
                            alt={"drc title"}
                        />

                        {/* Desktop title */}
                        <img
                            className='hidden md:block h-48'
                            src={title}
                            alt={"drc title"}
                        />

                    </div>
                </div>

                <Mars />

                <ProjectHeader
                    className={"-mt-64 z-10"}
                    description={"I was contracted as a freelance UI/UX designer with the responsibility of designing a cloud compute platform for a machine learning startup."}
                    about={"DR Cloud is a state-of-the-art cloud compute platform for machine learning researchers to easily manage virtual cloud compute and monitor deep learning experiments."}
                    timeframe={"2023 | 1 Month"}
                    role={"UX/UI Designer & Researcher"}
                    platforms={"Desktop"}
                    category={"AI, Cloud Compute"}
                />

                <FullScreenImage src={drc_collage} srcMobile={drc_collage} />

                <PageSection>

                    <div className='flex flex-col gap-8 justify-center'>

                        <div className='flex flex-col gap-8 md:gap-16 justify-center'>
                            <SectionHeader label={"Research"} />

                            <h3 className='text-white text-xl md:text-2xl font-bold'>
                                Client Brief
                            </h3>
                        </div>

                        <div className='flex flex-col xl:flex-row gap-16 items-center'>
                            <div className='flex flex-col gap-4 items-center xl:w-3/4'>
                                <p className='text-drcGray text-base md:text-xl'>
                                    The client requested the design of a 
                                    <span className='font-semibold'>
                                         {" cloud computing platform "}
                                    </span> 
                                    for their 
                                    <span className='font-semibold'>
                                         {" machine learning engineers "}
                                    </span> 
                                    to allow for 
                                    <span className='font-semibold'>
                                         {" simple and fast orchestration of virtual cloud machines with sophisticated monitoring"}
                                    </span>
                                    . 
                                </p>
                                <p className='text-drcGray text-base md:text-xl'>
                                    At that moment, a terminal-based application was used by the client. I was tasked with designing a frontend to allow for a browser-based extension of the backend.
                                    The project was guided by the clients' requests and my own studies of the prominent existing cloud compute providers
                                    such as Azure, AWS, Google Cloud and Oracle Cloud. 
                                    
                                </p>
                            </div>

                            <div className='hidden md:flex md:flex-col md:gap-2'>
                                <img
                                    className='select-none'
                                    src={drc_terminal}
                                    alt={`drc terminal image`}
                                />
                                <p className='text-drcGray text-sm text-right'>
                                    Content is intentionally blurred to safeguard privacy.
                                </p>
                            </div>

                        </div>

                    </div>
                </PageSection>

                <FullScreenImage src={drc_collage_screens} srcMobile={drc_collage_mobile} />

                <PageSection>
                    <div className='flex flex-col gap-16'>
                        <div className='flex flex-col gap-8'>

                            <div className='flex flex-row gap-8 justify-between items-center'>
                                <div className='flex flex-col gap-4 md:w-2/3'>
                                    <h3 className='flex text-white text-xl md:text-2xl text-left font-bold'>
                                        Getting Started
                                    </h3>
                                    <p className='text-drcGray text-base md:text-xl text-left'>
                                        Before this project, <span></span>I had no understanding of cloud platforms—I had never even seen one! I also had no idea of the requirements of machine learning engineers. What does “training” a model even mean?
                                    </p>
                                    <p className='text-drcGray text-base md:text-xl text-left'>
                                        I embarked on a journey of self-education by studying the designs of various cloud compute providers—but
                                        the websites are very complex and have so many features;
                                        I felt a bit lost and out of my depth. But thanks to support from the client,
                                        clear product requirements, and interviews with the engineers,
                                        I was able to isolate the features and concerns that mattered.
                                        Based on the engineers and employees I spoke with,
                                        I created several user personas to guide the design.
                                    </p>
                                </div>

                                <div className='hidden md:flex md:flex-col md:gap-2 w-1/2'>
                                    <img
                                        className='select-none max-h-96 rounded-md ml-auto'
                                        src={requirements}
                                        alt={`client requirements`}
                                        style={{ filter: 'invert(0.89)' }}
                                    />
                                    <p className='text-drcGray text-sm text-right'>
                                        Screenshot of one of the requirement documents from the client
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </PageSection>

                <PageSection>
                    <div className='flex flex-col gap-16'>
                        <div className='flex flex-col gap-8'>
                            <div className='flex flex-col gap-4 md:w-2/3'>
                                <h3 className='flex text-white text-xl md:text-2xl text-left font-bold'>
                                    User Personas
                                </h3>
                                <p className='text-drcGray text-base md:text-xl text-left'>
                                    Based on discussions with various employees and interested parties, I created 3 different personas.
                                    Alex Thompson is the distilled engineer-persona and served as my main guideline as most users are engineers.
                                    However there are also additional personas representing the minority: product and management.
                                    My goal was to create a designing to accomodate all concerns.
                                </p>
                            </div>
                        </div>
                    </div>
                </PageSection>

                <PageSection className={"bg-drcDarkGray 4xl:bg-darkblack"}>
                    <div className='flex flex-col gap-16 pt-10 pb-10 md:pt-20 md:pb-20 4xl:pl-20 4xl:pr-20 bg-drcDarkGray'>
                        <PersonaSection
                            about={["Tech enthusiast and a machine learning whiz with over five years of experience, he loves diving into the world of technology and earned his Master's degree in Computer Science. Alex works at an exciting startup, where he trains lots of machine learning models every day.",
                                "Alex loves training machine learning models, but hates spending time configuring his cloud development environment. His employer built their own servers that are hosted in a data-centre, but accessing the compute is not so easy. Currently he uses a terminal application to start-up virtual compute nodes called “Vessels”, but sometimes model training gets stuck (“deadlock”).",
                                "He wishes he could get a unified frontend platform that simplifies the vessel creation process, makes it simpler to manage his vessels, the models training and also makes it simpler to collaborate with colleagues."
                            ]}
                            pictureSrc={persona_1}
                            nameTitle={"Alex Johnson, 29"}
                            jobTitle={"Senior Machine Learning Engineer"}
                            location={"London, UK"}
                            needs={["A platform that streamlines the process of setting up and managing virtual cloud compute resources.",
                                "An intuitive and user-friendly interface that simplifies navigation and reduces the learning curve.",
                                "Collaborative features to facilitate communication and knowledge sharing among team members."
                            ]}
                            painPoints={["The current terminal-based interface is not user-friendly and lacks features, causing frustration and slowing down tasks.",
                                "Lack of collaborative tools in the current system hampers effective teamwork and information exchange."
                            ]}
                        />

                        <PersonaSection
                            about={["As a product manager Ariel actively studies the market and produces market research reports based on emerging trends.",
                                "While not a machine learning engineer, Ariel still wishes she was able to easily follow and track the progress of certain models relevant to particular product research and reports. She has never used a terminal application and does not understand the current vessel management system.",
                            ]}
                            pictureSrc={persona_2}
                            nameTitle={"Ariel Xi, 27"}
                            jobTitle={"Product Manager"}
                            location={"London, UK"}
                            needs={["Simple platform to monitor model training and experiments",
                            ]}
                            painPoints={["Difficulty understanding and using the terminal-based application, leading to poor understanding of current research",
                            ]}
                        />

                        <PersonaSection
                            about={["Sebastian—like Alex—is a massive tech nerd, and has been working in the machine learning field for the majority of his career. However, unlike Alex, he no longer does much coding and training of models, instead he manages a team of researchers.",
                                "There are often complains voiced within the team that the current infrastructure is hard to access, it is unclear what GPUs are available, and how research can collaborate effectively. In addition, Sebastian would prefer a simpler way to get an overview of the model training within the team. If he can’t effectively track the research progress he can’t effectively plan ahead. ",
                            ]}
                            pictureSrc={persona_3}
                            nameTitle={"Sebastian Novél, 33"}
                            jobTitle={"Head of Research"}
                            location={"London, UK"}
                            needs={["Alleviate the complains within the team and streamline the infrastructure access.",
                                "Improved ways of monitoring a large number of experiments from his research group.",
                                "Understand if the current compute budget will cover the current team’s research needs."
                            ]}
                            painPoints={["Team members are unhappy about the current terminal app, and onboarding new members is more complex.",
                                "Difficulty monitoring experiments of the team.",
                                "Difficult to mitigate any possible GPU shortages for the team without proper monitoring."
                            ]}
                        />
                    </div>
                </PageSection>

                <PageSection>
                    {/* Desktop */}
                    <div className='flex flex-col justify-between gap-32'>

                        <div className='flex flex-col md:flex-row gap-8 justify-between'>

                            <div className='flex flex-col gap-8'>
                                <h3 className='flex text-white text-xl font-bold'>
                                    Information Architecture
                                </h3>

                                <div className='flex text-drcGray text-base md:text-xl'>
                                    <div className='flex flex-col gap-4'>
                                        <p>
                                            Designing the platform's information architecture was really hard due to its numerous functions!
                                            It took time to organize everything logically. Sorting functions based on similarities and importance.
                                        </p>
                                        <p>
                                            Collaborating with stakeholders and users for feedback was crucial for me in achieving a well-structured information architecture.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <img
                                className='mb:hidden rounded w-full h-full select-none'
                                src={architecture}
                                alt={`drc image`}
                            />
                        </div>

                        <div className='flex flex-col md:flex-row-reverse gap-8 justify-between'>

                            <div className='flex flex-col gap-8'>

                                <h3 className='flex text-white text-xl font-bold'>
                                    Lo-Fi Wireframes
                                </h3>

                                <div className='flex text-drcGray text-base md:text-xl'>
                                    <div className='flex flex-col gap-4'>
                                        <p>
                                            I created rough, low-fi sketches on my notebook to visualize the initial
                                            concepts for the platform's design.
                                        </p>
                                        <p>
                                            I know they are not pretty, but these sketches got me off the ground quickly and served as a
                                            quick and accessible way to convey early ideas and explore different layouts.
                                        </p>
                                        <p>
                                            While not detailed, they provided a tangible starting point for
                                            discussions with stakeholders and potential users.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-row gap-4 justify-center h-full w-full'>

                                <div className='flex flex-col gap-4'>
                                    <img
                                        className='rounded w-full h-full select-none'
                                        src={wireframe_1}
                                        alt={`drc image`}
                                    />
                                    <img
                                        className='rounded w-full h-full select-none'
                                        src={wireframe_2}
                                        alt={`drc image`}
                                    />
                                </div>

                                <div className='flex flex-col gap-4'>
                                    <img
                                        className='rounded w-full h-full select-none'
                                        src={wireframe_3}
                                        alt={`drc image`}
                                    />
                                    <img
                                        className='rounded w-full h-full select-none'
                                        src={wireframe_4}
                                        alt={`drc image`}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </PageSection>

                <PageSection>
                    <div className='flex flex-col gap-8 md:gap-32'>
                        <div className='flex flex-col gap-6'>
                            <h1 className='text-white text-3xl font-bold'>
                                UI Design
                            </h1>
                            <p className='text-drcGray text-base md:text-xl font-normal md:w-2/3'>
                                Next we will explore how all the design elements
                                came together to create a unique minimalistic platform tailored to the personas
                                above.
                            </p>
                        </div>
                    </div>
                </PageSection>

                <PageSection>
                    <div className='flex flex-col gap-8 md:gap-32'>

                        <VesselPage
                            label="Vessels"
                            about={["Unified and sleak overview of all compute containers. Native support for various notifications help inform the user of important updates."
                            ]}
                            src={drc_vessels}
                            infos={VesselsInfo}
                        />

                        <VesselPage
                            label="Add vessel"
                            about={["Creating virtual compute containers, is the most common operation on the platform. Made super simple with the small modal window, purposefully centered to stay in focus and reduce motion."
                            ]}
                            src={drc_add_vessel}
                        />

                        <VesselPage
                            label="Filters"
                            about={[(
                                <span>
                                    <span> {"Both the "} </span>
                                    <span className='uppercase text-base md:text-lg tracking-widest'> {"Vessels"} </span>
                                    <span> {"and"}</span>
                                    <span className='uppercase text-base md:text-lg tracking-widest'> {"Experiments"} </span>
                                    <span> {" sections support a rich filtering system, partially inspired by the AWS cloud platform."} </span>
                                </span>
                            )]}
                            src={drc_filters}
                        />

                        <VesselPage
                            label="Experiments"
                            about={["Overview of machine learning experiments was requested by all personas. In this section a summary of experiments is presented, and may be easily filtered."]}
                            src={drc_experiments}
                            infos={VesselExperimentInfo}
                        />

                        <VesselPage
                            label="Vessel Page"
                            about={["Command center of each vessel. Connection details, an overview of hardware and experiments.",
                            ]}
                            src={drc_vessel_page}
                            infos={VesselPageInfo}
                        />

                        <VesselPage
                            label="Hardware"
                            about={["Understand internal compute resource distributions among users and easily tracking and manage hardware resources. It was important for multiple personas this data was easily accessible. Note that it can be visualized both per hardware type (queue) or per user.",
                            ]}
                            src={drc_hardware}
                        />

                        <VesselPage
                            label="My Profile"
                            about={["Centralises user settings and information. Currently the only configurable section is SSH key management, allowing each user to easily add and remove keys."
                            ]}
                            src={drc_my_profile}
                            infos={VesselMyProfileInfo}
                        />

                    </div>
                </PageSection>

                <PageSection>
                    <div className='flex flex-col gap-8 md:gap-32'>
                        <div className='flex flex-col gap-6'>
                            <h3 className='text-white text-xl md:text-2xl font-bold'>
                                What I Learnt
                            </h3>
                            <div className='flex flex-col gap-4 text-drcGray text-base md:text-xl font-normal md:w-2/3'>
                                <p>
                                    <span className="font-semibold">I started this project knowing nothing about cloud compute platforms</span>. Throughout this experience, I learned the crucial importance of studying and interviewing users, especially when in unfamiliar territory.
                                </p>
                                <p>
                                    What stood out was the in-depth collaboration: frequent calls, discussions and refining the design together. This dynamic exchange of ideas enriched the process, making it a shared exploration towards a meaningful design.
                                </p>
                                <p>
                                    In essence, this journey highlighted the impact of user-centricity and collaborative engagement. It showed that, even without prior expertise, understanding users deeply and approaching information with structure lays the groundwork towards great design.
                                </p>
                            </div>
                        </div>
                    </div>
                </PageSection>

                <PageSection>
                    <img
                        className='w-96'
                        src={thank_you}
                    />
                </PageSection>

                <NextProject>
                    <SkillWorksProject />
                </NextProject>

            </PageColumn>
        </PageWithCoverHeaderFooter>
    )
}

export default DRC;