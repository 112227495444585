import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const SEO = ({ title = null, description, image }) => {
  const location = useLocation();
  const fullUrl = `${window.location.origin}${location.pathname}`;
  const defaultTitle = "design. by Kadyrai.";

  if (title != null) {
    title = defaultTitle + " | " + title;
  } else {
    title = defaultTitle;
  }

  return (
    <Helmet>
      {/* Title & Description */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph / Facebook */}
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}

      {/* Twitter */}
      <meta property="twitter:url" content={fullUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {image && <meta property="twitter:image" content={image} />}

      {/* Additional tags can be added here as needed */}
    </Helmet>
  );
};

export default SEO;