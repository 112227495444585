import { useState } from 'react';
import { Link } from 'react-router-dom';
import { pushCustomEventToClarity } from './Clarity';

const CTAButton = (props) => {

    const { label, response, onClick, classNameMessage, link = null} = props;

    const [displayClickResponse, setDisplayClickResponse] = useState(false);

    const handleOnClick = () => {
        pushCustomEventToClarity("cta-button | " + label);
        onClick()
        if (!displayClickResponse) {
            setDisplayClickResponse(true);
            setTimeout(() => {
                setDisplayClickResponse(false);
            }, 3000);
        }
    }

    return (
        <div className='flex flex-col gap-2'>
            <div className='group flex flex-row gap-2 items-center cursor-pointer'>
                <h4 className='text-gray-300 md:text-xl font-gilroy font-bold group-hover:text-white' onClick={() => handleOnClick()}>
                    {link == null ? 
                        // internal link
                        label : (
                            <Link to={link} target="_blank">
                                {label}
                            </Link>
                        )}
                </h4>
                <div className='bg-gray-300 w-4 h-px transition-all duration-500 group-hover:translate-x-4 group-hover:bg-white'/>
            </div>
            <div className='relative'>
                <div className={`${classNameMessage} absolute p-2 bottom-8 md:bottom-0 md:top-0 right-8 text-white bg-darkblack bg-opacity-75 rounded-md text-sm transition-opacity duration-500 ${displayClickResponse ? 'opacity-100': 'opacity-0'}`}>
                    {response}
                </div>
            </div>
        </div>
    );
}

export default CTAButton;

// const GetInTouch = (props) => {

//     const { label, response, onClick } = props;

//     const email = "kadyrai.98@gmail.com"
//     const [displayClickResponse, setDisplayClickResponse] = useState(false);

//     const copyToClipboard = () => {
//         onClick()
//         navigator.clipboard.writeText(email);
//         if (!displayClickResponse) {
//             setDisplayClickResponse(true);
//             setTimeout(() => {
//                 setDisplayClickResponse(false);
//             }, 3000);
//         }
//     }

//     return (
//         <div className='flex flex-col gap-2'>
//             <div className='group flex flex-row gap-2 items-center cursor-pointer'>
//                 <div className='text-gray-300 md:text-xl font-gilroy font-bold group-hover:text-white' onClick={() => copyToClipboard()}>
//                     Get in touch
//                 </div>
//                 <div className='bg-gray-300 w-4 h-px transition-all duration-500 group-hover:translate-x-4 group-hover:bg-white'>
//                 </div>
//             </div>
//             <div className={`text-white text-sm pl-8 transition-opacity duration-500 ${displayClickResponse ? 'opacity-100': 'opacity-0'}`}>
//                 My email has been copied to your clipboard ✌
//             </div>
//         </div>
//     );
// }

// export default GetInTouch;