import { useState } from 'react';

import cv from '../assets/resume.svg'
import cvPdf from '../assets/resume.pdf'
import SEO from '../components/SEO';

const Resume = (props) => {
    const [displayDownloadButton, setDisplayDownloadButton] = useState(false);
    return (
        <div>
            <SEO
                title="Resumé"
                description="Checkout the CV of Kadyrai Zaruova."
            />
            <div className='flex flex-col items-center bg-darkblack'>
                <div className='relative bg-white'>
                    <img
                        className='relative z-0'
                        src={cv}
                        alt={`cv`}
                        onLoad={() => { setDisplayDownloadButton(true) }}
                    />
                    <div className='absolute top-1 md:top-1 right-6 md:right-12'>
                        <a href={cvPdf} target="_blank" rel="noopener noreferrer" download>
                            <button
                                className={`rounded-full ${displayDownloadButton ? 'opacity-100 md:opacity-75' : 'opacity-0'} text-xs md:text-base font-bold font-gilroy py-1 px-4 md:py-1 md:px-4 bg-gradient-to-r from-resumeButtonColor1 to-resumeButtonColor2 text-white transition-opacity duration-500 hover:opacity-100`}
                                type="button">
                                Download
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Resume;