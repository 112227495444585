import React, { useState, useEffect } from 'react';
import ghostApi from '../components/GhostAPI';
import PageWithCoverHeaderFooter from '../components/PageWithCoverHeaderFooter';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageColumn from '../components/PageColumn';
import PageSection from '../components/PageSection';
import kadyrai_profile from '../assets/skillworks/kadyrai_profile_picture.jpg'
import BlogThumbnails, { formatDate } from '../components/BlogThumbnails';
import { isMobile } from 'react-device-detect';

const extractTextFromSpan = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const span = doc.querySelector('span');
  if (span) {
    return span.textContent
  } else {
    return null
  }
};

export const Arrow = ({ height = 24, width = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    fill="none"
    viewBox={`8 8 8 8`}
  >
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" d="M10.438 8.875 13.562 12l-3.124 3.125" />
  </svg>
)

const Author = ({ name, src, date, readingTime }) => {
  return (
    <div className='flex flex-row items-center gap-2 my-4'>
      <Link to='/about'>
        <img
          src={src}
          className='w-16 h-16 rounded-full border-white border-2 hover:border-pinkDesigner duration-300'
        />
      </Link>
      <div className='flex flex-col gap-0'>
        <Link to='/about'>
          <span className='font-bold text-white font-gilroy text-xl hover:text-pinkDesigner duration-300'>
            {name}
          </span>
        </Link>
        <span className='text-gray-400 font-gilroy'>
          {formatDate(date)} — {readingTime} min read
        </span>
      </div>
    </div>
  )
}

const Tags = ({})

const BlogSection = ({ className, children }) => (
  isMobile ? (
    <PageSection className={className}>
      {children}
    </PageSection>
  ) : (
    <PageSection className={className} subClassName={"!w-1/2"}>
      {children}
    </PageSection>
  )
);

const BlogPost = ({ }) => {
  let { slug } = useParams();
  let navigate = useNavigate();
  const [post, setPost] = useState(null);

  const BackToBlog = () => (
    <div className='inline-flex -translate-x-4'>
      <Link to='/blog' className='group/button text-gray-300 hhover:text-pinkDesignerBright duration-500 border-opacity-0 hover:border-opacity-20 border-pinkDesigner border-2 rounded-lg'>
        <div className='flex flex-row gap-2 -translate-x-0 items-center flex-shrink px-4 py-1'>
          <div className="rotate-180 group-hover/button:-translate-x-1 duration-500 -translate-y-[1px]">
            <Arrow height={16} width={16} />
          </div>
          <span className='text-lg'>
            Blog
          </span>
        </div>
      </Link>
    </div>
  )

  useEffect(() => {
    ghostApi.posts
      .read({ slug: slug }, { formats: ['html'], include: ['tags'] })
      .then((post) => {
        setPost(post);
        console.log(post);
      })
      .catch((err) => {
        // Re-route back to main page
        navigate('/')
      });
  }, [slug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <PageWithCoverHeaderFooter title="Blog" transitionScreen={false}>
      <PageColumn className={"animate-fade-in"}>
        <article className='font-gilroy text-white'>
          <BlogSection className={"mt-24 md:mt-32 pb-4"}>
            <div className='group flex flex-col gap-2'>
              <BackToBlog />
              <h1 className='text-4xl md:text-6xl text-pinkDesigner'>{post.title}</h1>

              <div className="flex flex-row gap-2 items-center">
                {post.tags && post.tags.map((tag, index) => (
                  <span key={index} className="text-base text-gray-300 opacity-100 group-hover:opacity-100 duration-500 select-none">
                    #{tag.name}
                  </span>
                ))}
              </div>

              <Author
                name={"Kadyrai Zaruova"}
                src={kadyrai_profile}
                date={post.published_at}
                readingTime={post.reading_time}
              />

            </div>
          </BlogSection>

          <BlogSection>
            <div className='flex flex-col gap-0 items-center pb-8'>
              <img
                className='md:w-3/4'
                src={post.feature_image}
              />
              <span className='text-white font-gilroy text-sm'>
                {extractTextFromSpan(post.feature_image_caption)}
              </span>
            </div>
          </BlogSection>

          <BlogSection>
            <section dangerouslySetInnerHTML={{ __html: post.html }} className="blogContent text-xl" />
          </BlogSection>
        </article>

        <PageSection>
          <BlogThumbnails title="Read More" filter={slug} />
        </PageSection>
      </PageColumn>
    </PageWithCoverHeaderFooter>
  );
};

export default BlogPost;