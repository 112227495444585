import { useState } from 'react';
import CTAButton from './CTAButton';
import GetInTouch from './CTAButton';

const Contact = () => {

    return (
        <div className='font-gilroy'>

            <div className="flex flex-col gap-6 md:gap-4">

                <div className='flex flex-col gap-8'>

                    <div className='flex flex-row'>
                        <div className='flex flex-col gap-0'>
                            <div className="w-full text-lg md:text-3xl text-white font-bold select-none">
                                Let's design something amazing!
                            </div>
                            <div className="w-full md:w-full text-base md:text-2xl text-gray-300 select-none">
                                Contact me to discuss your design needs.
                            </div>
                        </div>
                    </div>
                </div>

                <CTAButton label="Get in touch" 
                           response="My email has been copied to your clipboard ✌️" 
                            onClick={() => navigator.clipboard.writeText("kadyrai.98@gmail.com")}
                />

            </div>

        </div>
    )
}

export default Contact;