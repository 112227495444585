import React, { useRef, useState, useEffect } from 'react';
import CTAButton from '../components/CTAButton';

import CVSummary from '../components/CVSummary';

import { isMobile } from 'react-device-detect';
import { BlurrySphere, pushCustomTagToClarity } from '../components';
import PageWithCoverHeaderFooter from '../components/PageWithCoverHeaderFooter';
import PageColumn from '../components/PageColumn';
import PageSection from '../components/PageSection';
import { kai_1, kai_1_cropped, kai_2_no_bg, kai_3, kai_4, kai_climbing, kai_snowbaord, linkedinIcon, quote_icon, spotify_logo, star, telegramIcon, travel_icon, upworkIcon } from '../assets';
import { Link } from 'react-router-dom';
import ExternalPlatformLogo from '../components/ExternalPlatformLogo';
import SEO from '../components/SEO';
import { ReactComponent as StarSvg } from '../assets/about/star.svg';
import { pushCustomEventToClarity } from '../components/Clarity';


const mapWeatherCodeToDescription = (code) => {
    const weatherCodeMap = {
        0: 'Sunny',
        1: 'Cloudy',
        2: 'Foggy',
        3: 'Drizzly',
        45: 'Freezing',
        48: 'Freezing',
        51: 'Rainy',
        53: 'Rainy',
        55: 'Rainy',
        56: 'Freezing',
        57: 'Freezing',
        61: 'Snowy',
        63: 'Snowy',
        65: 'Snowy',
        66: 'Freezing',
        67: 'Freezing',
        71: 'Snowy',
        73: 'Icy',
        75: 'Icy',
        77: 'Snowy',
        80: 'Showery',
        81: 'Showery',
        82: 'Showery',
        85: 'Snowy',
        86: 'Snowy',
        95: 'Stormy',
        96: 'Stormy',
        99: 'Stormy'
    };
    return weatherCodeMap[code] || '';
}

class Forecast {
    constructor(temperature, weatherDescription) {
        this.temperature = temperature
        this.weatherDescription = weatherDescription
    }

    toString() {
        if (this.temperature !== undefined && this.weatherDescription !== undefined) {
            return `${this.temperature}°C ${this.weatherDescription.toLowerCase()} `
        }
        return ''
    }
};

const getCurrentForecast = (latitude, longitude, onComplete) => {
    return fetch(`https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true`)
        .then(response => response.json())
        .then(data => {
            const temperature = Math.round(data.current_weather.temperature);
            const weatherCode = data.current_weather.weathercode;
            const weatherDescription = mapWeatherCodeToDescription(weatherCode);

            onComplete(new Forecast(temperature, weatherDescription));
        })
        .catch(error => {
            console.error('Error fetching weather data:', error);
            return { temperature: null, weatherDescription: 'Error fetching data' };
        });
}

const Spheres = ({ divRef }) => {

    const style1 = "bg-gradient-to-r from-tealDesignerDark to-pinkDesigner opacity-20 filter blur-lg";
    const style2 = "bg-gradient-to-r from-pinkDesigner to-pinkDesigner opacity-30 filter blur-3xl";
    const style3 = "bg-gradient-to-r from-darkSkyBlue via-white to-darkSkyBlue opacity-20 filter blur-md";

    const sizeCSS1 = 96;
    const sizeCSS2 = 96;
    const sizeCSS1_Mobile = 64;
    const sizeCSS2_Mobile = 64;
    const sizeCSS3 = 8

    return (
        <div>

            {isMobile ? (
                <div className='opacity-80'>
                    <BlurrySphere style={style1} sizeCSS={sizeCSS1_Mobile} containingDivRef={divRef} doAnimate={false} angleOffset={0} seed={5} positionOffset={{ x: 32, y: 128 + 0 }} />
                    <BlurrySphere style={style2} sizeCSS={sizeCSS2_Mobile} containingDivRef={divRef} doAnimate={false} angleOffset={0} seed={6} positionOffset={{ x: -16, y: -48 + 0 }} />

                    <BlurrySphere style={`h-8 w-8 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} doAnimate={false} angleOffset={0} seed={103} positionOffset={{ x: 0, y: 84 }} />

                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} followMouse={false} doAnimate={true} jitterFactor={0.3} angleOffset={0} seed={104} positionOffset={{ x: -55, y: 144 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} followMouse={false} doAnimate={true} jitterFactor={0.3} angleOffset={0} seed={105} positionOffset={{ x: -16, y: 96 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} followMouse={false} doAnimate={true} jitterFactor={0.3} angleOffset={0} seed={106} positionOffset={{ x: -64, y: 96 }} />
                </div>
            ) : (
                <div>
                    <BlurrySphere style={style2} sizeCSS={sizeCSS2} followsMouse={false} containingDivRef={divRef} angleOffset={0} seed={6} positionOffset={{ x: -32, y: 128 }} />
                    <BlurrySphere style={style1} sizeCSS={sizeCSS1} followsMouse={false} containingDivRef={divRef} angleOffset={0} seed={5} positionOffset={{ x: 128, y: 64 }} />

                    <BlurrySphere style={`h-8 w-8 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} angleOffset={0} seed={103} positionOffset={{ x: -64, y: 84 }} />

                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} angleOffset={0} seed={104} positionOffset={{ x: -54, y: 96 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} angleOffset={0} seed={105} positionOffset={{ x: -54, y: 96 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} angleOffset={0} seed={106} positionOffset={{ x: -54, y: 96 }} />
                </div>
            )
            }
        </div>
    );
};

class Song {
    constructor(title, arist, spotifyLink) {
        this.title = title;
        this.artist = arist;
        this.spotifyLink = spotifyLink;
    }
}

const ReviewStars = ({ rank, className, fill = "#00eca5" }) => {
    return (
        <div className="flex flex-row gap-0 -ml-[6px] md:-ml-2">
            {Array(rank).fill().map((_, index) => (
                <div key={index} className="scale-[25%] h-4 w-4 md:scale-[40%] md:h-6 md:w-6 opacity-60 md:group-hover/review:opacity-100 duration-500">
                    <StarSvg fill={fill} height={38} width={38} />
                </div>
            ))}
        </div>
    );
}

const MusicExtension = ({ songs }) => {
    const song = songs[Math.floor(Math.random() * songs.length)];

    const onClickLink = () => {
        pushCustomEventToClarity("spotify | " + song.title);
    };

    return (
        <a href={song.spotifyLink} target="_blank" rel="noopener noreferrer" onClick={onClickLink}>
            <div className='group flex px-4 xl:px-8 bg-uxUiDesignerColor select-none' >
                <div className='flex flex-row items-center gap-4 xl:gap-8 bg-pinkDesigner opacity-100 py-4'>
                    <img
                        className='h-8 w-8 xl:h-10 xl:w-10 opacity-100 transition-all duration-500 group-hover:invert'
                        src={spotify_logo}
                    />
                    <div className='flex flex-col gap-0 font-gilroy'>
                        <span className='text-white font-sans text-base xl:text-base uppercase tracking-widest'>
                            On Repeat
                        </span>

                        <div className='hidden xl:inline'>
                            <span className='text-darkblack text-sm xl:text-base font-bold font-gilroy'>
                                {song.title}
                            </span>
                            <span className='text-darkblack text-sm xl:text-xl ffont-bold font-gilroy'>
                                {" | "}
                            </span>
                            <span className='text-darkblack -translate-y-2 text-sm md:text-base ffont-bold font-gilroy'>
                                {song.artist}
                            </span>
                        </div>
                        <div className='xl:hidden flex flex-row gap-2 items-center'>
                            <div className='absolute w-0.5 h-9 -translate-y-1 bg-darkblack' />
                            <div className='pl-2 flex flex-col gap-1'>
                                <span className='text-darkblack text-base font-bold font-gilroy'>
                                    {song.title}
                                </span>
                                <span className='text-darkblack -translate-y-2 text-base ffont-bold font-gilroy'>
                                    {song.artist}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

const StockholmCard = () => {
    return (
        <div className='relative'>
            <div className='absolute w-full bottom-0 z-0 bg-tealDesignerDark h-[55%] opacity-20 rounded-full blur-3xl' />
            <img
                style={{ borderRadius: '1000px 1000px 300px 300px' }}
                className='relative rounded-[25px] border-4 border-tealDesignerDark z-5 opacity-100'
                src={kai_3}
            />
            <div style={{ borderRadius: isMobile ? '0px 0px 50px 50px' : '0px 0px 90px 90px' }}
                className='absolute w-full -bottom-6 z-6 bg-tealDesignerDark h-24 md:h-48 bg-opacity-100'>
                <div className='flex flex-row gap-8 px-8 md:px-16 items-center w-full h-full p-4'>
                    <img
                        className='h-12 w-12 md:h-16 md:w-16'
                        src={travel_icon}
                    />
                    <div className='flex flex-col justify-center m-2 md:m-8'>
                        <span className='text-white text-4xl uppercase tracking-widest font-normal font-sans'>
                            SWEDEN
                        </span>
                        <span className='text-darkblack text-xs md:text-base font-bold font-gilroy'>
                            Stockholm in the summer was gorgeous.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ThailandCard = () => {
    return (
        <div className='relative'> <div className='absolute w-full bottom-1/4 z-0 bg-uxUiDesignerColor h-[55%] opacity-60 rounded-full blur-3xl' />
            <img
                style={{ borderRadius: '300px 300px 1000px 1000px' }}
                className='relative rounded-[25px] border-4 border-uxUiDesignerColor z-5 opacity-100'
                src={kai_4}
            />
            <div style={{ borderRadius: isMobile ? '40px 40px 0px 0px' : '90px 90px 0px 0px' }}
                className='absolute w-full -top-6 z-6 bg-uxUiDesignerColor h-24 md:h-48 bg-opacity-100'>
                <div className='flex flex-row gap-8 px-8 lg:px-16 items-center w-full h-full p-4'>
                    <img
                        className='h-12 w-12 md:h-16 md:w-16'
                        src={travel_icon}
                    />
                    <div className='flex flex-col justify-center m-2 md:m-8'>
                        <span className='text-white text-2xl md:text-4xl uppercase tracking-widest font-normal font-sans'>
                            THAILAND
                        </span>
                        <span className='text-darkblack text-xs md:text-base font-bold font-gilroy'>
                            I had the best pad-thai ever on this beach.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProfileCard = () => {
    return (
        <div className='w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-full 2xl:w-full 3xl:w-full -my-16 md:my-0'>
            <div className='flex justify-center'>
                <div className='flex flex-col gap-0 w-3/4 md:w-full'>
                    <img
                        style={{ borderRadius: '1000px 1000px 0px 0px' }}
                        className='border-uxUiDesignerColor border-2 sm:max-h-96 md:max-h-96 xl:max-h-screen rounded-tl-full object-cover select-none opacity-100'
                        src={kai_1_cropped}
                        alt={`Profile image`}
                    >
                    </img>
                    <MusicExtension songs={[
                        new Song("We Are", "ONE OK ROCK", "https://open.spotify.com/track/57sk9X1fPLXRfkw74XNrmK?si=c08440e4dfa64f02"),
                        new Song("Renegades", "ONE OK ROCK", "https://open.spotify.com/track/29VVYrV5TVpGu0IfoTXlcw?si=7bd4ff1b517342e2"),
                        new Song("Stand Out Fit In", "ONE OK ROCK", "https://open.spotify.com/track/4zXRMjTaeflesB3Ih4eHnJ?si=a343a10d46f44428"),
                        new Song("The Beginning", "ONE OK ROCK", "https://open.spotify.com/track/4f3nDjgqXurMryYBSp0TZD?si=226dd6e1b42047b6"),
                        new Song("Taking Off", "ONE OK ROCK", "https://open.spotify.com/track/6rH7DwITrq2MhxSSuL91r9?si=8b9124a6d48d4e09"),
                        new Song("Mr. gendai Speaker", "ONE OK ROCK", "https://open.spotify.com/track/1BXzZW6KsV0UgmVHJ5CUnx?si=e04d94eaca774284"),
                        new Song("Change", "ONE OK ROCK", "https://open.spotify.com/track/0BOHIMI5EvhGuZSkWLwxIP?si=ea8776898dc44ea7"),
                        new Song("Avalanche", "Bring Me The Horizon", "https://open.spotify.com/track/4EyPadLFhtWojU7mkT5hqT?si=ecc5490d00c648dd"),
                        new Song("Boy's a Liar", "PinkPantheress", "https://open.spotify.com/track/6AQbmUe0Qwf5PZnt4HmTXv?si=9a3135594b934766"),
                        new Song("Namamekashiki ", "DIR EN GREY", "https://open.spotify.com/track/0PFAQlY7D7yBwJAanpChL4?si=08f524d0195d45df"),
                        new Song("ブルーバード", "Ikimonogakari", "https://open.spotify.com/track/2XpV9sHBexcNrz0Gyf3l18?si=0c08f24769de4109"),
                        new Song("Crying Lightning", "Arctic Monkeys", "https://open.spotify.com/track/6wVWJl64yoTzU27EI8ep20?si=fb002ce6ca344e5a"),
                        new Song("Odyssey", "Dream Koala", "https://open.spotify.com/track/3sSHcojhD6MdCwWa9ICXd2?si=713886825a3c4047"),
                        new Song("Lost Kingdom", "God Is An Astronaut", "https://open.spotify.com/track/19YPGbCVBVOoK2Ynfkb06W?si=4ada756037504f02"),
                        new Song("After Dark", "Mr.Kitty", "https://open.spotify.com/track/2LKOHdMsL0K9KwcPRlJK2v?si=1f52c2497cd84ca2"),
                        new Song("Safe and Sound", "Mogu", "https://open.spotify.com/track/3IwLR6cyV6tDANGEAiMzow?si=59d7f9d0b9aa4f85"),
                        new Song("I CAN'T STOP ME", "TWICE", "https://open.spotify.com/track/3IwLR6cyV6tDANGEAiMzow?si=59d7f9d0b9aa4f85"),
                        new Song("NUNU NANA", "Jessi", "https://open.spotify.com/track/2cUzIBGMvx2BZ2Q1fzjdl1?si=92eb231c305f4dd6"),
                        new Song("KAZINO", "BIBI", "https://open.spotify.com/track/245onUPHGD1DYuiacxTuW0?si=9a89b430274148d8"),
                        new Song("IPlayYouListen", "ODESZA", "https://open.spotify.com/track/3xhR3mClWXydDCByJxnOwY?si=0a1c07821ec3405f"),
                        new Song("Toys", "IYES", "https://open.spotify.com/track/6xGQ2hz992pXtSNhtOUuHW?si=b7379485c6eb4726"),
                        new Song("Glow", "IYES", "https://open.spotify.com/track/5cn8pxas6oLgBqNnCivDUG?si=868333b93b7a4172"),
                    ]} />
                </div>
            </div>
        </div>
    )
}

const ReviewGridCell = ({ label, review, rank = 5, labelClassName }) => {
    return (
        <div className='flex flex-row gap-8 justify-center items-center'>
            <h2 className={`text-2xl md:text-5xl text-pinkDesigner tracking-widest w-8 md:w-16 ${labelClassName}`}>
                {label}
            </h2>
            <div className='group/review flex flex-col gap-2'>
                <ReviewStars rank={rank} />
                <p className='text-gray-300 text-sm tabular-nums md:text-xl md:w-[90%]'>
                    {review}
                </p>
            </div>
        </div>
    )
}

const About = (props) => {

    const [forecast, setForecast] = useState(null);

    useEffect(() => {
        // Almaty
        // getCurrentForecast(43.2380, 76.8829, (forecast) => { setForecast(forecast); });
        // London
        getCurrentForecast(51.5072, 0.1276, (forecast) => { setForecast(forecast); });
    }, [])

    const landingDivRef = useRef(null);

    return (
        <PageWithCoverHeaderFooter title={"About"} subtitle={"Me."}>

            <SEO
                title="About"
                description="Learn more about me. Professionally, hobbies, my travels—even my Spotify favourites."
            />

            <PageColumn>

                <div ref={landingDivRef}>

                    <Spheres divRef={landingDivRef} />

                    <PageSection className={"my-24 md:my-32"}>
                        <div className='flex flex-col gap-12 justify-center items-center'>
                            <div>
                                <h1 className='text-5xl md:text-8xl text-white opacity-0 delay-500 font-bold animate-fade-in'>
                                    <span>I'm </span>
                                    <span className='text-pinkDesigner font-bold italic'>Kadyrai</span>
                                    <span>.</span>
                                </h1>
                            </div>

                            <div className={`flex flex-col xl:flex-row items-center pt-12 md:pt-0 gap-16 md:gap-32 z-10`}>

                                <ProfileCard />

                                <div className='flex flex-col gap-8 pt-16 md:pt-0'>

                                    <div className='text-4xl md:text-6xl text-white font-semibold'>
                                        <h2>
                                            A Product Designer working from {forecast == null ? '' : forecast.toString()}London, UK.
                                        </h2>
                                    </div>

                                    <div className='text-base md:text-2xl text-white'>
                                        <p>
                                            I started my career at a large fintech company, designing novel financial processes and platforms.
                                            Currently I work at a fast-paced design agency where I lead multiple projects.
                                            <br />
                                            <br />
                                            I am passionate about creating seamless user experiences that bridge the gap between technology and people.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PageSection>
                </div>

                <PageSection>
                    <div className='flex flex-col -my-24 md:mb-16 gap-8 md:gap-0'>
                        <CVSummary />
                        <CTAButton label="Checkout my resumé"
                            response="Thanks for having a look ❤️"
                            onClick={() => { }}
                            link="/about/resume"
                            classNameMessage={"left-8 -top-8"}
                        />
                    </div>
                </PageSection>

                <PageSection className={"pt-16 md:pt-0"}>
                    <div className='flex flex-col md:flex-row gap-8'>
                        <div className='flex flex-col justify-start md:w-1/2'>
                            <div className='hidden md:block -rotate-3 md:-rotate-6'>
                                <ThailandCard />
                            </div>
                        </div>

                        <div className='flex flex-col justify-between md:w-1/2'>
                            <div className='flex flex-col gap-16 md:gap-8 items-end text-right'>
                                <h1 className='text-pinkDesigner font-bold text-4xl md:text-6xl'>
                                    See ya Figma.<br />Hello World!
                                </h1>
                                <div className='md:hidden pl-4 -rotate-3 md:-rotate-6'>
                                    <ThailandCard />
                                </div>
                                <p className='text-white text-left md:text-right text-md md:text-2xl'>
                                    <span>
                                        I am a high-heel dancer and world traveler. I love exploring new corners of the globe—real and fictional. Find me in the vast world of
                                    </span>
                                    <span className='font-hylia'>
                                        {" The Legend of Zelda—Hyaah!"}
                                    </span>
                                </p>
                            </div>
                            <div className='pt-16 md:pt-[196px] -rotate-6 md:rotate-6'>
                                <StockholmCard />
                            </div>
                        </div>
                    </div>

                </PageSection>

                <PageSection className={"bg-drcDarkGray bg-opacity-40"}>
                    <div className='flex flex-col md:flex-row gap-8 items-end py-16 md:py-32'>

                        <div className='flex flex-col gap-8 items-start text-left md:w-1/2'>
                            <h1 className='text-tealDesignerDark font-bold text-4xl md:text-6xl'>
                                I'm always looking for new challenges.
                            </h1>

                            {/* Mobile only */}
                            <div className='flex md:hidden z-10 flex-row gap-16'>
                                <div>
                                    <img
                                        className='rounded-3xl'
                                        src={kai_snowbaord}
                                    />
                                </div>
                            </div>

                            <p className='text-white text-base md:text-2xl'>
                                I'm terrified of heights but somehow decided learning climbing was a good idea.
                                And I'm contemplating switching from snowboard to skiing—I've never skied.
                            </p>

                            <p className='text-white text-base md:text-2xl'>
                                I love languages ❤️ On my transition pages you can see Kazakh, Chinese and Japanese.
                                I spent many years in Beijing and self-taught Japanese.
                            </p>

                            <div className='hidden md:block group relative flex z-10 flex-row gap-16'>
                                <img
                                    className='relative rounded-3xl'
                                    src={kai_snowbaord}
                                />
                                <div
                                    style={{ borderRadius: '0px 0px 24px 24px' }}
                                    className='hidden md:block absolute bottom-0 h-0 group-hover:h-24 opacity-0 group-hover:opacity-100 duration-500 ease-in-out w-full bg-opacity-80 bg-tealDesignerDark'>
                                    <div className='flex justify-center items-center h-full'>
                                        <p className='text-white text-3xl'>
                                            Mountains make me happy 💚
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='group relative flex justify-left md:w-1/2 overflow-hidden'>
                            <img
                                className='relative rounded-3xl'
                                src={kai_climbing}
                            />
                            <div
                                style={{ borderRadius: '24px 24px 0px 0px' }}
                                className='hidden md:block absolute top-0 h-0 group-hover:h-24 opacity-0 group-hover:opacity-100 duration-500 ease-in-out w-full bg-opacity-80 bg-pinkDesigner'>
                                <div className='flex justify-center items-center h-full'>
                                    <p className='text-white text-3xl'>
                                        Not gonna lie—I was terrified 😰
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageSection>

                <PageSection>

                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-row items-right'>
                            <div className='md:w-1/3' />
                            <h1 className='text-pinkDesigner font-bold text-4xl md:text-6xl md:w-2/3'>
                                Let's collaborate and design something cool!
                            </h1>
                        </div>
                        <div className='flex flex-col md:flex-row items-right'>
                            <div className='md:w-1/3' />
                            <p className='md:w-2/3'>
                                <span className='text-white font-normal text-base md:text-2xl md:w-1/2'>
                                    In my portfolio you will find a range of interesting products covering all platforms
                                    and topics such as video streaming, compression, cloud computing.
                                    <br />
                                    <br />
                                    In addition to my main job, I've worked hundreds of hours as a freelancer on
                                </span>
                                <a href="https://www.upwork.com/freelancers/~01f65cf428a48273e2?s=1044578476142100545"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => { pushCustomEventToClarity("about | upwork reviews") }}>
                                    <span className='text-tealDesigner font-bold text-base md:text-2xl md:w-1/2 duration-500 opacity-100 md:opacity-70 hover:opacity-100'>
                                        {" UpWork"}
                                    </span>
                                </a>
                                <span className='text-white font-normal text-base md:text-2xl md:w-1/2'>
                                    {". I'm proud and happy of the feedback I've received."}
                                </span>
                            </p>
                        </div>

                        <div className='group relative'>

                            <div className='absolute z-0 h-full w-full md:translate-y-[5%] bg-gradient-to-b md:bg-gradient-to-r from-pinkDesigner to-tealDesigner md:via-darkblack md:to-tealDesigner rounded-2xl md:rounded-[64px] opacity-10 blur-2xl' />
                            <div className='group relative flex z-10 flex-col gap-4 bg-drcDarkGray bg-opacity-100 mt-8 md:mt-16 p-6 md:p-16 opacity-100 rounded-2xl md:rounded-[64px]'>
                                <div>
                                    <img
                                        className='fill-white invert h-6 w-6 md:h-12 md:w-12'
                                        src={quote_icon}
                                    />
                                </div>

                                <div className='relative grid grid-cols-1 lg:grid-cols-2 gap-4'>
                                    <ReviewGridCell
                                        label={"01"}
                                        review={"Kadyrai delivered a professional design with excellent use of Figma in terms of design-system and components.  She provides great feedback and ideas during brainstorming [...]"}
                                        // hack to fake monospacing of numbers
                                        labelClassName={"pr-2"}
                                    />
                                    <ReviewGridCell
                                        label={"02"}
                                        review={"I'm very happy with the final design. Kadyrai was not only speedy but also incredibly skilled at Figma [...]  It was easy to work with her [...]"}
                                    />
                                    <ReviewGridCell
                                        label={"03"}
                                        review={"Professional design, delivered quickly. Designer with great attention to detail and a good understanding of platform design principles."}
                                    />
                                    <ReviewGridCell
                                        label={"04"}
                                        review={"[...] The final design was modern, sleek and matched our company style and requested functionality perfectly. Overall a super strong Figma designer with fast response time and quick delivery [...]"}
                                    />
                                </div>
                                <div className='flex w-full justify-end'>
                                    <img
                                        className='fill-white invert h-6 w-6 md:h-12 md:w-12 -scale-[100%]'
                                        src={quote_icon}
                                    />
                                </div>
                                <div className='flex w-full justify-end gap-8'>
                                    <Link to="https://www.upwork.com/freelancers/~01f65cf428a48273e2?s=1044578476142100545" target="_blank">
                                        <span className='text-tealDesigner text-xs md:text-base w-1/2 duration-500 opacity-100 md:opacity-70 hover:opacity-100'>
                                            See all reviews and projects on my profile.
                                        </span>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>

                </PageSection>

                <PageSection>
                    <div className='flex flex-col gap-2 mb-32 md:mb-0'>
                        <div className='flex pt-32'>
                            <div className='flex flex-col gap-32'>
                                <h2>
                                    <span className='text-white text-4xl md:text-6xl'>
                                        {"Thank "}
                                    </span>
                                    <span className='text-pinkDesigner text-4xl md:text-6xl font-bold'>
                                        {"you "}
                                    </span>
                                    <span className='text-white text-4xl md:text-6xl'>
                                        {"for checking out my website."}
                                    </span>
                                </h2>
                            </div>
                        </div>
                        <div className='hidden md:flex'>
                            <h3 className='text-white text-2xl md:text-4xl'>
                                It took me a long time to design and develop.
                            </h3>
                        </div>
                        <div className='hidden md:flex flex-col gap-8 justify-right pt-8 pb-16 md:pb-0'>
                            <div className='flex flex-col items-left gap-0'>
                                <CTAButton label="Let me know what you think ❤️"
                                    response="👋 My email is in your clipboard"
                                    onClick={() => navigator.clipboard.writeText("kadyrai.98@gmail.com")}
                                    classNameMessage={"-top-20 !left-48"}
                                />
                                <div className='flex flex-row gap-4'>
                                    <ExternalPlatformLogo
                                        src={telegramIcon}
                                        alt={"telegram"}
                                        link={"https://t.me/z_kadyrai"}
                                    />
                                    <ExternalPlatformLogo
                                        src={linkedinIcon}
                                        alt={"linkedin"}
                                        link={"https://www.linkedin.com/in/kadyrai-zaruova"}
                                    />
                                    <ExternalPlatformLogo
                                        src={upworkIcon}
                                        alt={"upwork"}
                                        link={"https://www.upwork.com/freelancers/~01f65cf428a48273e2?s=1044578476142100545"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </PageSection>


            </PageColumn>
        </PageWithCoverHeaderFooter>
    )
}

export default About