import PageSection from "./PageSection";
import Project from "./Project";
import ProjectInfo from "./ProjectInfo";

const ProjectHeader = ({description, about, timeframe, role, platforms, category, className}) => {
    return (
        <PageSection className={className}>
            <div className='flex flex-col justify-center gap-8'>
                <div className='flex flex-col gap-8 z-10 pt-16 md:pt-0 font-gilroy'>
                    <h3 className='text-white text-xl md:text-2xl font-bold'>
                        {description}
                    </h3>
                    <h3 className='text-white text-xl md:text-2xl'>
                        {about}
                    </h3>
                </div>

                <ProjectInfo
                    timeframe={timeframe}
                    role={role}
                    platforms={platforms}
                    category={category}
                />

            </div>
        </PageSection>
    );
};

export default ProjectHeader;