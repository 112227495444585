import PageSection from "./PageSection";
import HorizontalSeparator from "./Separator";

const NextProject = ({ title = "Next Project", children }) => {
    return (
        <PageSection className={"my-32 z-10"} subClassName={"!px-0"}>
            {/* <HorizontalSeparator /> */}
            <div className='flex flex-col gap-8'>
                <div className='text-white text-5xl md:text-6xl font-bold px-8 md:px-0'>
                    {title}
                </div>
                {children}
            </div>
        </PageSection>
    );
};

export default NextProject;