import { useState, useEffect, useRef } from 'react';

import { isMobile } from "react-device-detect";
import { BlurrySphere, VerticalNote } from "../components";

const Cover = ({ title, subtitle, isAnimated }) => {

    const style1 = `bg-gradient-to-r from-white to-black opacity-20 filter blur-lg`;
    const style2 = `bg-gradient-to-r from-white to-black opacity-30 filter blur-3xl`;
    const style3 = `bg-gradient-to-r from-white to-block opacity-20 filter blur-md`;

    const sizeCSS1 = 96;
    const sizeCSS2 = 96;
    const sizeCSS3 = 8

    const [doAnimate, setDoAnimate] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDoAnimate(isAnimated);
        }, 50);
        return () => clearTimeout(timer);
    }, []);

    const divRef = useRef(null);
    return (

        <div ref={divRef} className="w-screen h-screen">
            <div className='absolute md:left-16 xl:left-1/2 top-1/3'>
                <div className="flex flex-col gap-8">
                    <VerticalNote />
                    <span className={`text-white font-bold font-gilroy text-5xl md:text-6xl uppercase transition-all ease-in-out duration-1500 -translate-x-8 opacity-0 ${doAnimate ? 'translate-x-8 opacity-100' : ''}`}>
                        {title}.
                    </span>
                    <span className={`text-white font-gilroy pl-8 md:pl-0 text-5xl md:text-6xl duration-1000 delay-[1000ms] uppercase ${doAnimate ? 'opacity-100' : 'opacity-0'}`}>
                        {subtitle}
                    </span>
                </div>
            </div>
            <div>
                <BlurrySphere style={style1} sizeCSS={sizeCSS1} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} angleOffset={0} seed={111} positionOffset={{ x: -256, y: 0 }} />
                <BlurrySphere style={style2} sizeCSS={sizeCSS2} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} angleOffset={0} seed={112} positionOffset={{ x: -312, y: -16 }} />

                <BlurrySphere style={`h-8 w-8 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={1032} positionOffset={{ x: -64, y: 84 }} />

                <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} angleOffset={0} seed={111} positionOffset={{ x: -54, y: 96 }} />
                <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} angleOffset={0} seed={112} positionOffset={{ x: -54, y: 96 }} />
                <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} angleOffset={0} seed={113} positionOffset={{ x: -54, y: 96 }} />
            </div>

        </div>
    );
}

const TransitionScreen = ({ title, subtitle = "By Kadyrai.", isAnimated = true, duration = 2300, children }) => {

    const [transitionPageIsVisible, setLandingIsVisible] = useState(true);
    const [transitionPageIsHidden, setLandingIsHidden] = useState(false);

    const transitionTime = 500;

    useEffect(() => {
        const timerVisible = setTimeout(() => {
            setLandingIsVisible(false);
        }, duration)
        const timerHidden = setTimeout(() => {
            setLandingIsHidden(true);
        }, duration + transitionTime);

        return () => {
            clearTimeout(timerVisible);
            clearTimeout(timerHidden);
        }
    }, [])

    return (
        <div>
            <div className={`z-0 w-screen h-screen transition-all duration-500 ${transitionPageIsVisible ? 'opacity-100' : 'opacity-0 invisible'} ${transitionPageIsHidden ? 'hidden' : ''}`}>
                <Cover title={title} subtitle={subtitle} isAnimated={isAnimated} />
            </div>

            <div className={`transition-opacity opacity-0 ease-out duration-500 ${transitionPageIsVisible ? 'hidden' : 'opacity-0'} ${transitionPageIsHidden ? 'opacity-100' : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default TransitionScreen;