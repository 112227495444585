import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import CascadingTextAnimation from './CascadingTextAnimation';

const VerticalNote = ({animate = true}) => {

    const [doAnimate, setDoAnimate] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (animate) { setDoAnimate(animate); }
        }, 0);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`absolute text-gray-500 top-full ${isMobile ? 'left-1/2' : ''} md:top-1/2 md:translate-x-1/2 md:right-0`}>
            <div className='flex flex-row gap-2'>
                <span className={`writing-mode-vertical font-gilroy transition-all duration-3000 ease-in-out ${true ? 'translate-y-0 opacity-100': '-translate-y-4 opacity-0'}`}>
                    <CascadingTextAnimation text={"Заруова Қадырай"} startDelay={0.5}/>
                </span>
                <span className={`writing-mode-vertical font-gilroy transition-all delay-100 duration-3000 ease-in-out ${doAnimate ? 'translate-y-0 opacity-100': '-translate-y-4 opacity-0'}`}>
                    <CascadingTextAnimation text={"卡迪賴·扎魯娃"} startDelay={0.75}/>
                </span>
                <span className={`writing-mode-vertical font-gilroy transition-all delay-200 duration-3000 ease-in-out ${doAnimate ? 'translate-y-0 opacity-100': '-translate-y-4 opacity-0'}`}>
                    <CascadingTextAnimation text={"カディライ・ザルオワ"} startDelay={1.0}/>
                </span>
            </div>
        </div>
    );
}


export default VerticalNote;