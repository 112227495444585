import React, { useState, useEffect, useRef } from 'react';

import { CiMenuBurger } from 'react-icons/ci';

import { isMobile, isSafari } from 'react-device-detect';

import Bubble from './Bubble'
import BlurrySphere from './BlurrySphere';
import Typewriter from './TypingText';
import GetInTouch from './CTAButton';
import { Link } from 'react-router-dom';
import CTAButton from './CTAButton';

import telegramIcon from '../assets/telegram.svg'
import upworkIcon from '../assets/upwork.svg'
import linkedinIcon from '../assets/linkedin.svg'
import GradientBorder from './GradientBorder';
import ExternalPlatformLogo from './ExternalPlatformLogo';
import pushCustomTagToClarity, { pushCustomEventToClarity } from './Clarity';

const Landing = () => {

    const landingDivRef = useRef(null);

    const style1 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-20 filter blur-lg";
    const style2 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-30 filter blur-3xl";
    const style3 = "bg-gradient-to-r from-blueParticle via-white to-blueParticle opacity-20 filter blur-md";

    const sizeCSS1 = 96;
    const sizeCSS2 = 96;
    const sizeCSS1_Mobile = 64;
    const sizeCSS2_Mobile = 64;
    const sizeCSS3 = 8

    const [landingDimensions, setLandingDimensions] = useState({ width: 0, height: 0 });

    const [nameIsVisible, setNameIsVisible] = useState(false);

    useEffect(() => {
        const nameVisibilityTimer = setTimeout(() => {
            setNameIsVisible(true);
        }, 150)
        if (landingDivRef.current) {
            const { width, height } = landingDivRef.current.getBoundingClientRect();
            setLandingDimensions({ width, height });
        }
    }, []);

    return (
        <div className="w-screen h-screen" ref={landingDivRef}>

            {isMobile ? (
                <div className='opacity-80'>
                    <BlurrySphere style={style1} sizeCSS={sizeCSS1_Mobile} containingDivRef={landingDivRef} doAnimate={false} angleOffset={0} seed={5} positionOffset={{ x: 32, y: 128 + 0 }} />
                    <BlurrySphere style={style2} sizeCSS={sizeCSS2_Mobile} containingDivRef={landingDivRef} doAnimate={false} angleOffset={0} seed={6} positionOffset={{ x: -16, y: -48 + 0 }} />

                    <BlurrySphere style={`h-8 w-8 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={landingDivRef} doAnimate={false} angleOffset={0} seed={103} positionOffset={{ x: 0, y: 84 }} />

                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={landingDivRef} doAnimate={false} followMouse={false} jitterFactor={0.3} angleOffset={0} seed={104} positionOffset={{ x: -55, y: 144 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={landingDivRef} doAnimate={false} followMouse={false} jitterFactor={0.3} angleOffset={0} seed={105} positionOffset={{ x: -16, y: 96 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={landingDivRef} doAnimate={false} followMouse={false} jitterFactor={0.3} angleOffset={0} seed={106} positionOffset={{ x: -64, y: 96 }} />
                </div>
            ) : (
                <div className=''>
                    <BlurrySphere style={style1} sizeCSS={sizeCSS1} containingDivRef={landingDivRef} followsMouse={false} angleOffset={0} seed={5} positionOffset={{ x: 128, y: 150 }} />
                    <BlurrySphere style={style2} sizeCSS={sizeCSS2} containingDivRef={landingDivRef} followsMouse={false} angleOffset={0} seed={6} positionOffset={{ x: 64, y: 100 }} />


                    {!isSafari ? (
                        <div>
                            <BlurrySphere style={`h-8 w-8 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={landingDivRef} angleOffset={0} seed={103} positionOffset={{ x: -64, y: 84 }} />
                            <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={landingDivRef} angleOffset={0} seed={104} positionOffset={{ x: -54, y: 96 }} />
                            <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={landingDivRef} angleOffset={0} seed={105} positionOffset={{ x: -54, y: 96 }} />
                            <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={landingDivRef} angleOffset={0} seed={106} positionOffset={{ x: -54, y: 96 }} />
                        </div>
                    ) : null}
                </div>
            )
            }

            {/* <VerticalNote /> */}

            <div className='flex justify-center'>

                <div className='md:w-3/4 max-w-screen-2xl'>

                    <div className="flex flex-col justify-center h-screen gap-4">
                        <div className='flex flex-col items-center'>
                            <h1 className="font-gilroy text-white font-bold text-6xl md:text-8xl select-none">
                                <div className={`${isMobile ? 'flex flex-row items-center gap-0' : ''}`}>
                                    <div className='md:inline'>
                                        <div className='md:inline opacity-0 animate-fade-in'>Hi there,</div>
                                        <span className='opacity-0 animate-fade-in'> I'm </span>
                                        <span className='relative group'>
                                            <div className={`hidden md:block absolute rounded-full bg-pinkDesigner duration-500 transition-all w-0 opacity-0 ${nameIsVisible ? 'group-hover:opacity-100 group-hover:w-[95%]' : ''} h-1 my-3 right-3 bottom-0`}></div>
                                            <Link to={"/about"} onClick={() => { pushCustomEventToClarity("landing | kadyrai click") }}>
                                                <span className='opacity-0 text-uxUiDesignerColor group-hover:text-white duration-500 italic animate-fade-in'>Kadyrai</span>
                                            </Link>
                                        </span>
                                    </div>
                                    <span className='hidden md:inline opacity-0 duration-500 animate-fade-in'> ✌️</span>
                                </div>
                            </h1>
                        </div>

                        <div className='flex justify-center'>
                            <div className="flex justify-center opacity-0 animate-fade-in text-2xl md:text-5xl font-gilroy text-white select-none text-left">
                                <Typewriter textArray={[
                                    "I am a product designer.",
                                    "I worked in Fintech.",
                                    "I am a top-rated freelancer.",
                                    "See my portfolio below.",
                                ]} />
                            </div>
                        </div>

                        {isMobile ? (<div></div>) : (
                            <div className='hidden md:flex flex-col gap-4 items-center font-gilroy'>
                                <div className="opacity-0 animate-pop-in-from-top flex flex-row gap-4">
                                    <Bubble text="productdesign" />
                                    <Bubble text="uxresearch" />
                                    <Bubble text="wireframing" />
                                    <Bubble text="adaptive" />
                                </div>
                                <div className="opacity-0 animate-pop-in-from-top flex flex-row gap-4">
                                    <Bubble text="figma" />
                                    <Bubble text="userflow" />
                                    <Bubble text="informationarchitecture" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div>
                    <div className='flex flex-col gap-4 opacity-0 animate-fade-in'>

                        <div className='absolute left-0 px-8 bottom-8 w-full'>

                            <div className="flex flex-row justify-between gap-16 md:gap-0 items-center">

                                <div className='flex flex-col gap-4'>

                                    <div className='flex flex-col items-left gap-0'>
                                        <CTAButton label="Contact Me"
                                            response="👋 My email is in your clipboard"
                                            onClick={() => navigator.clipboard.writeText("kadyrai.98@gmail.com")}
                                            classNameMessage={"!-top-20 !-right-28 md:!-right-48"}
                                        />
                                        <div className='flex flex-row gap-4'>
                                            <ExternalPlatformLogo
                                                src={telegramIcon}
                                                alt={"telegram"}
                                                link={"https://t.me/z_kadyrai"}
                                            />
                                            <ExternalPlatformLogo
                                                src={linkedinIcon}
                                                alt={"linkedin"}
                                                link={"https://www.linkedin.com/in/kadyrai-zaruova"}
                                            />
                                            <ExternalPlatformLogo
                                                src={upworkIcon}
                                                alt={"upwork"}
                                                link={"https://www.upwork.com/freelancers/~01f65cf428a48273e2?s=1044578476142100545"}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="flex flex-row gap-4 items-center select-none">
                                        <p className="font-gilroy text-sm md:text-xl">
                                            <span className="text-uxUiDesignerColor opacity-100 md:opacity-75 transition italic font-normal duration-500 hover:opacity-100">Product Designer </span>
                                            <br />
                                            <span className='text-white'>
                                                Almaty, Kazakhstan
                                            </span>
                                        </p>
                                        <div className="text-3xl md:text-4xl md:pt-2">
                                            <span className=''>✌️</span>
                                        </div>
                                    </div> */}


                                </div>
                                <Link to="/about/resume" target="_blank">
                                    <GradientBorder colorRGB={[255, 139, 174]} viaColorRGB={[255, 150, 190]} className={"p-0.5 rounded-full"} animate={true}>
                                        <button
                                            className="rounded-full font-bold font-gilroy font-base md:text-xl p-4 pl-4 pr-4 md:p-4 md:pl-6 md:pr-6 opacity-100 bg-transparent text-white transition-opacity duration-500 hover:opacity-100"
                                            type="button">
                                            Resumé
                                        </button>
                                    </GradientBorder>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Landing;