import { useState, useEffect } from "react";

import { CiMenuBurger } from "react-icons/ci";
import { Link } from "react-router-dom"
import Menu from "../container/Menu";
import MenuButton from "./MenuButton";

import logo from '../assets/logo.svg'

const Header = () => {

    return (
        <div className="relative">
            <div className="4xl:flex justify-center">
                <div className="4xl:w-2/3">
                    <div className="relative">
                        <div className='fixed z-50 top-6 md:top-4 left-6'>
                            <Link to="/">
                                <div className="md:hover:rotate-45 opacity-100 md:opacity-50 hover:opacity-100 duration-500 transition-all transform">
                                    <img
                                        className="relative fill-white h-12 w-12 md:h-16 md:w-16"
                                        src={logo}
                                    />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Menu />
        </div>
    )
}

export default Header;