import { useState, useEffect } from 'react';

const MenuButton = (props) => {

    const {isOpen, animate = true} = props;

    const [doAnimate, setDoAnimate] = useState(animate);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDoAnimate(false);
        }, 0);
    
      return () => { clearTimeout(timer); }
    }, []);
    
    return (
        <div>
            {isOpen ? (
                <div className={`group flex flex-col gap-3 opacity-100 md:opacity-75 hover:opacity-100 transform transition-all duration-500`}>
                    <div className={`h-0.5 w-8 bg-white rounded-full transform transition-all duration-500 ${doAnimate ? 'translate-y-2' : 'group-hover:-translate-y-1'}`}></div>
                    <div className={`h-0.5 w-8 bg-white rounded-full transform transition-all duration-500 ${doAnimate ? '-translate-y-2' : 'group-hover:translate-y-1'}`}></div>
                </div>
            ): (
                <div className={`flex flex-col gap-3 opacity-100 md:opacity-50 hover:opacity-100 transform transition-all duration-500`}>
                    <div className={`h-0.5 w-8 bg-white rounded-full transform transition-all duration-500 ${doAnimate ? '-translate-y-1' : 'translate-y-[7px]'}`}></div>
                    <div className={`h-0.5 w-8 bg-white rounded-full transform transition-all duration-500 ${doAnimate ? 'translate-y-1' : '-translate-y-[7px]'}`}></div>
                </div>
            )}
        </div>
    );
}

export default MenuButton;