import React, { useRef } from 'react';

import PasswordPrompt from '../components/PasswordPrompt';
import BlurrySphere from '../components/BlurrySphere';

import ExternalPlatformLogo from '../components/ExternalPlatformLogo';
import telegramIcon from '../assets/telegram.svg';
import linkedinIcon from '../assets/linkedin.svg';
import upworkIcon from '../assets/upwork.svg';

import { isMobile } from 'react-device-detect';

const SocialLinks = () => (
    <div className='flex flex-row gap-4'>
        <ExternalPlatformLogo
        src={telegramIcon}
        alt={"telegram"}
        link={"https://t.me/z_kadyrai"}
        />
        <ExternalPlatformLogo
        src={linkedinIcon}
        alt={"linkedin"}
        link={"https://www.linkedin.com/in/kadyrai-zaruova"}
        />
        <ExternalPlatformLogo
        src={upworkIcon}
        alt={"upwork"}
        link={"https://www.upwork.com/freelancers/~01f65cf428a48273e2?s=1044578476142100545"}
        />
    </div>
);
const PasswordPage = ({ password, setPassword, handleLogin, isPending }) => {
    const divRef = useRef(null);

    const style1 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-20 filter blur-lg";
    const style2 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-30 filter blur-3xl";

    const sizeCSS1 = 96;
    const sizeCSS2 = 96;

    const sizeCSS3 = 48;
    const sizeCSS4 = 64;

    return (
        <div className="bg-darkblack min-h-screen flex items-center justify-center" ref={divRef}>
            {isMobile ? (
                <div className='absolute inset-0 z-0 opacity-50'>
                    <BlurrySphere style={style1} doAnimate={false} sizeCSS={sizeCSS3} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={5} positionOffset={{ x: 32, y: 64 }} />
                    <BlurrySphere style={style2} doAnimate={false} sizeCSS={sizeCSS4} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={6} positionOffset={{ x: -32, y: 64 }} />
                </div>
            ) : (
                <div className='absolute inset-0 z-0'>
                    <BlurrySphere style={style1} doAnimate={true} sizeCSS={sizeCSS1} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={11} jitterFactor={0.5} positionOffset={{ x: 128, y: 96 }} />
                    <BlurrySphere style={style2} doAnimate={true} sizeCSS={sizeCSS2} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={12} jitterFactor={0.5} positionOffset={{ x: 0, y: 128 }} />
                </div>
            )}

            <div className='flex flex-col items-center justify-center gap-8 z-10'>
                <PasswordPrompt
                    password={password}
                    setPassword={setPassword}
                    handleLogin={handleLogin}
                    isPending={isPending}
                />
                <SocialLinks />
            </div>
            <div className="hidden md:block absolute bottom-4 left-4 text-gray-500 opacity-40 font-thin text-xs md:text-base select-none">
                Махаббатпен жасалған | Заруова Қадырай © {(new Date().getFullYear())}
            </div>
        </div>
    );
}

export default PasswordPage;
