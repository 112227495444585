import React, { useState, useEffect } from 'react';
import ghostApi from './GhostAPI';

export const truncateText = (text, length) => {
    if (text == null) {
        return text
    }
    if (text.length <= length) {
        return text;
    } else {
        return text.substring(0, length) + '...';
    }
};

export const formatDate = (dateString) => {
    if (dateString == null) {
        return null
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
};


const Separator = (props) => {
    const { className } = props
    return (
        <div className="flex justify-center">
            <hr className={`my-2 border-gray-700 w-full h-1 mx-4 mmd:mx-64 ${className}`} />
        </div>
    )
}
const BlogThumbnails = ({ title, filter: filterbySlug, limit = "all", variant = 0, excerptLength = 96 }) => {
    const [posts, setPosts] = useState(null);

    useEffect(() => {
        ghostApi.posts
            .browse({
                limit: limit,
                include: "authors",
                fields: "title,feature_image,slug,primary_author,published_at,excerpt,id"
            })
            .then((posts) => {
                setPosts(posts);
            })
            .catch((err) => {

            });
    }, []);



    return (
        <div className='flex flex-col'>
            {posts && title &&
                <h3 className="text-3xl md:text-4xl text-white font-gilroy uppercase pl-4 pb-8">
                    {title}
                </h3>
            }
            <div className={`grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4`}>
                {posts && posts.map((post, index) => (
                    <React.Fragment key={post.id}>
                        {/* { post && index < posts.length && post.slug != filterbySlug && <Separator /> } */}
                        { post && post.slug != filterbySlug &&
                            <a href={`/blog/${post.slug}`} className="block no-underline text-white mb-4 p-4 rounded-lg duration-300">
                                <div className="group flex flex-col md:flex-row items-center gap-4">
                                    <img src={post.feature_image} alt={post.title} className="w-full h-48 md:w-32 md:h-32 object-cover flex-shrink-0 opacity-100 md:opacity-50 group-hover:opacity-100 duration-500 rounded-md" />
                                    <div className="flex-grow font-gilroy">
                                        <h3 className="text-xl font-bold group-hover:text-pinkDesigner duration-500">{post.title}</h3>
                                        <p className="text-gray-300">{truncateText(post.excerpt, excerptLength)}</p>
                                        <div className="text-gray-500 text-sm mt-2">
                                            By
                                            <span className='font-bold'>
                                                {` ${post.primary_author.name} `}
                                            </span>
                                            | {formatDate(post.published_at)}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        }
                    </React.Fragment>
                ))}
            </div>
        </div>
    );

};

export default BlogThumbnails;