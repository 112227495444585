import { useState } from 'react';

export const ImageContainer = ({ src, alt, onLoad, className, style = {}, imgClassName }) => (
    <div className={className} style={style}>
      <img
        className={`absolute inset-0 object-cover w-full h-full transform transition-all duration-1000 ${imgClassName}`}
        src={src}
        alt={alt}
        onLoad={onLoad}
      />
    </div>
);

 const ProjectThumbnail = ({ isMobile, comingSoon, src, srcMobile, sideBySide = false }) => {
    const effectiveSrc = isMobile || sideBySide ? srcMobile : src;
    const baseImgClassName = 'opacity-100 md:opacity-50 group-hover:opacity-100 group-hover:scale-105 cursor-pointer';

    const [imageHeight, setImageHeight] = useState(0);

    const handleImageLoad = (event) => {
        const ratio = event.target.naturalHeight / event.target.naturalWidth;
        var multiplier = 3 / 2
        if (!isMobile) {
            multiplier = sideBySide ? 0.33 : 0.6
        }
        const height = `calc(120vw * ${ratio} * ${multiplier})`;
        setImageHeight(height);
    };

    return (
      <div className='relative'>
        {isMobile || comingSoon ? (
          <img
            className={`w-full h-full transform transition-all duration-1000 ${comingSoon ? 'opacity-50' : 'md:opacity-100 hover:opacity-100 cursor-pointer'}`}
            src={effectiveSrc}
            alt={`image`}
            onLoad={handleImageLoad}
          />
        ) : (
          <ImageContainer
            className="relative w-full p-8 md:rounded-xl md:overflow-hidden"
            style={{ height: imageHeight }}
            src={effectiveSrc}
            alt={`image`}
            onLoad={handleImageLoad}
            imgClassName={baseImgClassName}
          />
        )}
        {comingSoon && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-darkblack rounded-lg bg-opacity-75 p-1 md:p-4">
            <span className='text-2xl md:text-4xl font-bold text-white shadow-black drop-shadow-lg select-none'>
              Coming Soon
            </span>
          </div>
        )}
      </div>
    );

};


export default ProjectThumbnail