import { useRef } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

import BlurrySphere from "./BlurrySphere";
import { pushCustomEventToClarity, pushCustomTagToClarity } from "./Clarity";
import Contact from "./Contact";

const Hero = () => {
    return (
        <div className="flex flex-col gap-1 items-start uppercase font-normal font-gilroy text-4xl md:text-6xl">
            <div className=" text-white rounded select-none transition-all pl-1 pr-1 duration-1000 opacity-75 bg-gradient-to-r from-resumeButtonColor1 to-resumeButtonColor2 hover:opacity-100">
                <span className='relative top-[5px]'>
                    DESIGN.
                </span>
            </div>
            <span className='text-gray-300 font-bold select-none'>
                BY KADYRAI.<br />
            </span>
        </div>
    );
};

const ExternalTextLink = ({ label, link }) => {

    const onClickLink = (label) => {
        pushCustomEventToClarity("footer | link-click | " + label);
    }

    return (
        <a href={link} target="_blank" rel="noopener noreferrer" onClick={() => (onClickLink(label))}>
            <div className="transition-all duration-500 hover:text-white">
                {label}
            </div>
        </a>
    );
};

const Footer = ({ className }) => {

    const divRef = useRef(null);

    const style1 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-20 filter blur-3xl";
    const style2 = "bg-gradient-to-r from-blueParticle to-purpleParticle opacity-30 filter blur-3xl";
    const style3 = "bg-gradient-to-r from-blueParticle via-white to-blueParticle opacity-20 filter blur-lg";

    const sizeCSS1 = 196;
    const sizeCSS2 = 96;
    const sizeCSS3 = 8;

    return (
        <div ref={divRef} className={`md:h-screen ${className} pb-4 md:pb-0`}>

            <div className="relative flex flex-col gap-8">

                <div className="flex flex-col md:flex-row justify-between">

                    <div className="flex flex-col gap-6 md:gap-8 h-full md:h-screen justify-center">
                        <Hero />
                        <Contact />
                    </div>

                    {isMobile ? (
                        null
                    ) : (
                        <BlurrySphere style={`hidden md:block h-196 w-196 ${style1}`} sizeCSS={sizeCSS1} doAnimate={true} containingDivRef={divRef} followsMouse={false} angleOffset={0} seed={111} positionOffset={{ x: -128, y: -256 }} />
                    )}

                    <div className="md:flex flex-col gap-8 h-full md:h-screen justify-center text-base md:text-xl font-gilroy text-gray-500 text-left">

                        <div className="hidden md:flex flex-col gap-2">
                            <div className="-translate-x-4 select-none font-bold text-gray-400 text-left">
                                {/* No title for section */}
                            </div>

                            <div className="hidden md:flex flex-col gap-2">
                                <Link to="/">
                                    <div className="transition-all duration-500 hover:text-white">
                                        Home
                                    </div>
                                </Link>
                                <Link to="/about">
                                    <div className="transition-all duration-500 hover:text-white">
                                        About
                                    </div>
                                </Link>
                                <Link to="/about/resume">
                                    <div className="transition-all duration-500 hover:text-white">
                                        Resumé
                                    </div>
                                </Link>
                                {/* <Link to="/blog">
                                    <div className="transition-all duration-500 hover:text-white">
                                        Blog
                                    </div>
                                </Link> */}
                            </div>
                        </div>

                        <div className="hidden md:flex flex-col gap-2">
                            <div className="-translate-x-4 select-none font-bold text-gray-400 text-left">
                                Projects
                            </div>
                            <Link to={`/project/videocodec`}>
                                <div className="transition-all duration-500 hover:text-white">
                                    VideoCodec
                                </div>
                            </Link>
                            <Link to={`/project/drc`}>
                                <div className="transition-all duration-500 hover:text-white">
                                    DRC
                                </div>
                            </Link>
                            <Link to={`/project/skillworks`}>
                                <div className="transition-all duration-500 hover:text-white">
                                    SkillWorks
                                </div>
                            </Link>
                        </div>

                        <div className="flex flex-row justify-between md:flex-col gap-2">
                            <div className="hidden md:block -translate-x-4 select-none font-bold text-gray-400 text-left">
                                Contacts
                            </div>
                            <ExternalTextLink
                                label={"Telegram"}
                                link={"https://t.me/z_kadyrai"}
                            />
                            <ExternalTextLink
                                label={"LinkedIn"}
                                link={"https://www.linkedin.com/in/kadyrai-zaruova"}
                            />
                            <ExternalTextLink
                                label={"UpWork"}
                                link={"https://www.upwork.com/freelancers/~01f65cf428a48273e2?s=1044578476142100545"}
                            />
                        </div>

                    </div>

                </div>

                <div className="hidden md:block absolute bottom-4 text-gray-500 opacity-40 font-thin text-xs md:text-base select-none">
                    Махаббатпен жасалған | Заруова Қадырай © {(new Date().getFullYear())}
                </div>

            </div>
        </div>
    );
};

export default Footer;