import { useCallback, useEffect, useRef, useState } from "react";
import BlogThumbnails, { formatDate, truncateText } from "../components/BlogThumbnails";
import PageColumn from "../components/PageColumn";
import PageSection from "../components/PageSection";
import PageWithCoverHeaderFooter from "../components/PageWithCoverHeaderFooter";
import SEO from "../components/SEO";
import { isMobile } from "react-device-detect";
import { BlurrySphere } from "../components";
import { BsDot } from "react-icons/bs";
import ghostApi from "../components/GhostAPI";
import { ImageContainer } from "../components/ProjectThumbnail";
import { Link } from "react-router-dom";
import Typewriter from "../components/TypingText";
import { Arrow } from "./BlogPost";

const Spheres = ({ divRef }) => {

    const style1 = "bg-gradient-to-r from-tealDesignerDark to-pinkDesigner opacity-10 filter blur-lg";
    const style2 = "bg-gradient-to-r from-pinkDesigner to-pinkDesigner opacity-10 filter blur-3xl";
    const style3 = "bg-gradient-to-r from-darkSkyBlue via-white to-darkSkyBlue opacity-20 filter blur-md";

    const sizeCSS1 = 196;
    const sizeCSS2 = 196;
    const sizeCSS1_Mobile = 64;
    const sizeCSS2_Mobile = 64;
    const sizeCSS3 = 8

    return (
        <div>

            {isMobile ? (
                <div className='opacity-80'>
                    <BlurrySphere style={style1} sizeCSS={sizeCSS1_Mobile} containingDivRef={divRef} doAnimate={false} angleOffset={0} seed={5} positionOffset={{ x: 32, y: 128 + 0 }} />
                    <BlurrySphere style={style2} sizeCSS={sizeCSS2_Mobile} containingDivRef={divRef} doAnimate={false} angleOffset={0} seed={6} positionOffset={{ x: -16, y: -48 + 0 }} />

                    <BlurrySphere style={`h-8 w-8 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} doAnimate={false} angleOffset={0} seed={103} positionOffset={{ x: 0, y: 84 }} />

                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} followMouse={false} doAnimate={true} jitterFactor={0.3} angleOffset={0} seed={104} positionOffset={{ x: -55, y: 144 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} followMouse={false} doAnimate={true} jitterFactor={0.3} angleOffset={0} seed={105} positionOffset={{ x: -16, y: 96 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} followMouse={false} doAnimate={true} jitterFactor={0.3} angleOffset={0} seed={106} positionOffset={{ x: -64, y: 96 }} />
                </div>
            ) : (
                <div>
                    <BlurrySphere style={style2} sizeCSS={sizeCSS2} followsMouse={false} containingDivRef={divRef} angleOffset={0} seed={6} positionOffset={{ x: -32, y: 0 }} />
                    <BlurrySphere style={style1} sizeCSS={sizeCSS1} followsMouse={false} containingDivRef={divRef} angleOffset={0} seed={5} positionOffset={{ x: 128, y: 16 }} />

                    {/* <BlurrySphere style={`h-8 w-8 ${style3}`} sizeCSS={sizeCSS3} containingDivRef={divRef} angleOffset={0} seed={103} positionOffset={{ x: -64, y: 84 }} /> */}

                    {/* <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} angleOffset={0} seed={104} positionOffset={{ x: -54, y: 96 }} /> */}
                    {/* <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} angleOffset={0} seed={105} positionOffset={{ x: -54, y: 96 }} /> */}
                    {/* <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} angleOffset={0} seed={106} positionOffset={{ x: -54, y: 96 }} /> */}
                </div>
            )
            }
        </div>
    );
};

const PostPreview = ({ post, variant = 'col' }) => {

    let flexDirection = "flex-col";
    if (variant == 'row') {
        flexDirection = "flex-col md:flex-row";
    } else if (variant == 'row-inverse') {
        flexDirection = "flex-col md:flex-row-reverse";
    }

    const baseImgClassName = 'opacity-100 md:opacity-50 group-hover:opacity-100 group-hover:scale-105 cursor-pointer';
    return (
        <Link to={`/blog/${post.slug}`}>
            <div className={`group flex ${flexDirection} gap-4`}>

                <ImageContainer
                    className="relative w-full h-96 p-8 md:rounded-xl md:overflow-hidden"
                    src={post.feature_image}
                    alt={post.title}
                    imgClassName={baseImgClassName}
                />

                <div className={`${variant.includes("row") ? 'md:w-1/2' : ''}`}>
                    <div className='flex flex-col gap-4'>
                        <div className="flex flex-row gap-2">
                            {post.tags && post.tags.map((tag, index) => (
                                <span key={index} className="text-sm text-pinkDesigner opacity-50 group-hover:opacity-100 duration-500 select-none">
                                    #{tag.name}
                                </span>
                            ))}
                        </div>
                        <h3 className='text-white text-2xl md:text-4xl font-bold font-gilroy tracking-wide'>
                            {post.title}
                        </h3>
                        <h4 className='text-gray-300 text-base md:text-xl'>
                            {truncateText(post.excerpt, 196)}
                        </h4>
                        <div className='flex flex-row items-center text-gray-500 text-base md:text-lg gap-1 md:gap-2'>
                            {formatDate(post.published_at)} <BsDot /> {post.reading_time} min read
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

const PostFilterMenu = ( { tags, filterByTags, onTagIsClicked }) => {

    const handleTagClick = (tag) => {
        onTagIsClicked(tag);
    };

    return (
        <div className="hidden md:grid md:grid-cols-8 gap-4 w-3/4">
            {tags.map((tag, index) => (
                <span key={index} onClick={() => handleTagClick(tag.name.toLowerCase())} className={`text-base ${filterByTags.includes(tag.name.toLowerCase()) ? 'text-pinkDesigner' : 'text-gray-300'} hover:text-pinkDesigner duration-500 cursor-pointer`}>
                    #{tag.name}
                    {/* #{tag.name} ({tag.count.posts}) */}
                </span>
            ))}
        </div>
    )
    
}

const PostsBentoGrid = ({ posts, className }) => {

    const renderPostPreview = (post, variant) => (
      <PostPreview key={post.slug} post={post} variant={variant} />
    );
  
    const renderDualPostRow = (index, post1, post2, isReversed = false) => (
      <div key={index} className="flex flex-col md:flex-row gap-8">
        <div className={`w-full ${isReversed ? 'md:w-[40%]' : 'md:w-[60%]'}`}>
          {post1 && renderPostPreview(post1, 'col')}
        </div>
        <div className={`w-full ${isReversed ? 'md:w-[60%]' : 'md:w-[40%]'}`}>
          {post2 && renderPostPreview(post2, 'col')}
        </div>
      </div>
    );
  
    return (
      <div className={`flex flex-col gap-8 ${className}`}>
        {posts.map((post, index) => {
          // Determine when to show a "large" post
          if (index % 3 === 0) {
            const variant = index % 6 === 0 ? 'row' : 'row-inverse'; // Alternate large post layout
            return renderPostPreview(post, variant);
          } else {
            const groupIndex = Math.floor(index / 3);
            if (index % 3 === 1) { // Start of a new dual post row
              const isReversed = groupIndex % 2 === 0;
              return renderDualPostRow(index, post, posts[index + 1], isReversed);
            }
            return null;
          }
        })}
      </div>
    );
  };
  

const Blog = () => {

    let divRef = useRef(null)

    const [posts, setPosts] = useState(null);
    const [tags, setTags] = useState([])
    const [filterByTags, setFilterByTags] = useState([])

    const onTagIsClicked = useCallback((tag) => {
        setFilterByTags((currentTags) => {
            const isTagActive = currentTags.includes(tag);
            return isTagActive ? currentTags.filter(t => t !== tag) : [...currentTags, tag];
        });
    }, [])

    useEffect(() => {
        const filterQuery = filterByTags.length > 0 ? filterByTags.map(tag => `tag:${tag}`).join('+') : null;
        ghostApi.posts
            .browse({
                limit: 12,
                include: "authors,tags",
                filter: filterQuery,
                fields: "title,feature_image,slug,primary_author,published_at,excerpt,id,reading_time"
            })
            .then((posts) => {
                setPosts(posts);
            })
            .catch((err) => {

            });
    }, [filterByTags]);

    useEffect(() => {
        ghostApi.tags
            .browse({
                limit: "all",
                include: "count.posts",
             })
            .then((tags) => {
                tags.filter(tag => tag.count.posts > 0);
                setTags(tags.sort((a, b) => a.count.posts < b.count.posts));
            })
            .catch((err) => {

            });
    }, [filterByTags]);

    return (
        <PageWithCoverHeaderFooter title="Blog" transitionScreen={false} className={"animate-fade-in"}>

            <SEO
                title="Blog"
                description="Blog posts from Kadyrai Zaruova."
            />

            <PageColumn>

                <PageSection>
                    <div ref={divRef}>

                        <Spheres divRef={divRef} />

                        <div className="flex flex-col justify-center h-screen gap-4">
                            <div className='flex flex-col gap-12 justify-center items-center'>
                                <h1 className='text-8xl md:text-8xl text-white opacity-0 delay-500 animate-fade-in'>
                                    <span className='text-pinkDesigner font-bold italic'>Blog</span>
                                </h1>
                                <div className='text-2xl md:text-6xl text-white opacity-0 delay-500 animate-fade-in'>
                                    <Typewriter className={"inline"} textArray={[
                                        "Thoughts, ideas & stories",
                                        "How do I approach design?",
                                        "What is my design process?",
                                        "See my favourite Figma plugins",
                                    ]} />
                                </div>
                                <div className="absolute bottom-16 animate-fade-in-blur">
                                    <div className="flex flex-col gap-0 animate-bounce">
                                        <div className="rotate-90 translate-x-2 duration-500 text-gray-300">
                                            <Arrow height={24} width={24} />
                                        </div>
                                        <div className="rotate-90 translate-x-2 duration-500 text-gray-300">
                                            <Arrow height={24} width={24} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </PageSection>

                <PageSection>
                    {posts && 
                        <div className="flex flex-col gap-4">
                            <PostFilterMenu tags={tags} filterByTags={filterByTags} onTagIsClicked={onTagIsClicked} />
                            <PostsBentoGrid posts={posts} className={"animate-fade-in"} />
                        </div>
                    }
                </PageSection>
            </PageColumn>
        </PageWithCoverHeaderFooter>
    )

}

export default Blog;