import Footer from "./Footer";
import Header from "./Header";
import PageSection from "./PageSection";
import TransitionScreen from "../container/TransitionScreen";

const PageWithCoverHeaderFooter = ({ title, subtitle = "By Kadyrai.", className, transitionScreen = true, children }) => {
    const Content = () => (
        <div>
            <Header />
            {children}
            <PageSection className={`!justify-normal md:!justify-center`}>
                <Footer />
            </PageSection>
        </div>
    )
    return (
        <div className={`font-gilroy ${className}`}>
            { transitionScreen ?
                <TransitionScreen title={title} subtitle={subtitle}>
                    <Content />
                </TransitionScreen>
                : 
                <Content />
            }
        </div>
    );
};

export default PageWithCoverHeaderFooter;