import React from 'react';

const CascadingTextAnimation = (props) => {
    const { text, startDelay, characterDelay = 0.10, className } = props
    return (
      <div>
        {text.split("").map((letter, index) => (
          <span
            className={`opacity-0 ${className}`}
            key={index}
            style={{
              animation: `fade-in-from-top 0.5s ease forwards ${startDelay + index * characterDelay}s`,
              display: 'inline-block'
            }}
          >
            {letter}
          </span>
        ))}
      </div>
    );
  };

export default CascadingTextAnimation;