import { createRef, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

import PageColumn from "../components/PageColumn"
import PageSection from "../components/PageSection";
import PageWithCoverHeaderFooter from "../components/PageWithCoverHeaderFooter"
import ProjectHeader from "../components/ProjectHeader";
import FullScreenImage from "../components/FullScreenImage";

import dr_logo from '../assets/video_app/dr_logo.svg'
import title from '../assets/video_app/title.svg'

import hero_image_1 from '../assets/video_app/hero_image_1.png'
import hero_image_2 from '../assets/video_app/hero_image_2.png'
import collage_1_desktop from '../assets/video_app/collage_1_desktop.jpg'
import collage_1_mobile from '../assets/video_app/collage_1_mobile.png'

import start_screen_mobile_1 from '../assets/video_app/start_screen/start_screen_mobile_1.png'
import start_screen_mobile_2 from '../assets/video_app/start_screen/start_screen_mobile_2.png'

import start_screen_tablet_1 from '../assets/video_app/start_screen/start_screen_tablet_1.png'
import start_screen_tablet_2 from '../assets/video_app/start_screen/start_screen_tablet_2.png'

import start_screen_desktop_1 from '../assets/video_app/start_screen/start_screen_desktop_1.png'

import key_features_mobile_1 from '../assets/video_app/key_features/key_features_mobile_1.png'
import key_features_mobile_2 from '../assets/video_app/key_features/key_features_mobile_2.png'
import key_features_mobile_3 from '../assets/video_app/key_features/key_features_mobile_3.png'
import key_features_mobile_4 from '../assets/video_app/key_features/key_features_mobile_4.png'

import key_features_tablet_1 from '../assets/video_app/key_features/key_features_tablet_1.png'
import key_features_tablet_2 from '../assets/video_app/key_features/key_features_tablet_2.png'
import key_features_tablet_3 from '../assets/video_app/key_features/key_features_tablet_3.png'

import key_features_desktop_1 from '../assets/video_app/key_features/key_features_desktop_1.png'
import key_features_desktop_2 from '../assets/video_app/key_features/key_features_desktop_2.png'
import key_features_desktop_3 from '../assets/video_app/key_features/key_features_desktop_3.png'

import live_call_mobile_1 from '../assets/video_app/live_call/live_call_mobile_1.png'
import live_call_mobile_2 from '../assets/video_app/live_call/live_call_mobile_2.png'
import live_call_mobile_3 from '../assets/video_app/live_call/live_call_mobile_3.png'
import live_call_mobile_4 from '../assets/video_app/live_call/live_call_mobile_4.png'

import live_call_tablet_1 from '../assets/video_app/live_call/live_call_tablet_1.png'
import live_call_tablet_2 from '../assets/video_app/live_call/live_call_tablet_2.png'
import live_call_tablet_3 from '../assets/video_app/live_call/live_call_tablet_3.png'
import live_call_tablet_4 from '../assets/video_app/live_call/live_call_tablet_4.png'

import live_call_desktop_1 from '../assets/video_app/live_call/live_call_desktop_1.png'
import live_call_desktop_2 from '../assets/video_app/live_call/live_call_desktop_2.png'
import live_call_desktop_3 from '../assets/video_app/live_call/live_call_desktop_3.png'

import vod_mobile_1 from '../assets/video_app/vod/vod_mobile_1.png'
import vod_mobile_2 from '../assets/video_app/vod/vod_mobile_2.png'

import vod_tablet_1 from '../assets/video_app/vod/vod_tablet_1.png'
import vod_tablet_2 from '../assets/video_app/vod/vod_tablet_2.png'

import vod_desktop_1 from '../assets/video_app/vod/vod_desktop_1.png'
import vod_desktop_2 from '../assets/video_app/vod/vod_desktop_2.png'

import landscape_mobile_1 from '../assets/video_app/landscape/landscape_mobile_1.png'
import landscape_mobile_2 from '../assets/video_app/landscape/landscape_mobile_2.png'

import landscape_tablet_1 from '../assets/video_app/landscape/landscape_tablet_1.png'
import landscape_tablet_2 from '../assets/video_app/landscape/landscape_tablet_2.png'

import landscape_desktop_1 from '../assets/video_app/landscape/landscape_desktop_1.png'

import arrow_left from '../assets/video_app/arrow_left.svg'
import arrow_right from '../assets/video_app/arrow_right.svg'

import { isMobile } from "react-device-detect";
import { BlurrySphere } from "../components";
import NextProject from "../components/NextProject";
import { DrcProject } from "../components/SelectedProjects";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { video_app_at_intel, video_app_at_microsoft } from "../assets";
import SEO from "../components/SEO";

const Logo = () => {

    return (
        <PageSection className={"my-32"}>
            <div className="flex flex-col items-center gap-2">
                <div className="relative">
                    <div className="absolute z-0 h-32 w-32 bg-white opacity-25 blur-3xl" />
                    <img
                        className="relative z-1 w-32 h-32"
                        src={dr_logo}
                    />
                </div>

                <img
                    className="h-32 w-96 -my-6"
                    src={title}
                />
                <h2 className="text-2xl text-codecSubtitle">
                    AI Video Codec Application
                </h2>

            </div>
        </PageSection>
    )
}

const SectionHeader = ({ label, className }) => {
    return (
        <div className={`inline-flex flex-row gap-6 items-center ${className}`}>
            <div className="w-2 h-2 rounded-full bg-drcGreen flex-none order-0"></div>
            <h1 className='text-drcGray text-lg uppercase tracking-widest font-sans pt-1'>
                {label}
            </h1>
        </div>
    )
}

const LandscapeViewSpheres = () => {

    const style1 = `bg-gradient-to-r from-drGreen to-black opacity-20 filter blur-3xl`;
    const style2 = `bg-gradient-to-r from-drGreen to-black opacity-20 filter blur-3xl`;
    const style3 = `bg-gradient-to-l from-brightPink to-black opacity-40 filter blur-3xl`;

    const sizeCSS1 = 4 * 49;
    const sizeCSS2 = 4 * 49;
    const sizeCSS3 = 4 * 49;

    const divRef = useRef(null);

    return (
        <div>
            {isMobile ? (
                <div className="absolute h-196">
                    <div className="flex flex-col gap-2">
                        <div className="h-48 w-48 bg-brightPink translate-x-1/2 -translate-y-1/2 opacity-40 blur-3xl" />
                        <div className="h-64 w-64 bg-drGreen translate-x-1/5 -translate-y-1/2 opacity-10 blur-3xl" />
                    </div>
                </div>
            ) : (
                <div className="absolute" div={divRef}>
                    <BlurrySphere style={style1} sizeCSS={sizeCSS1} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} jitterFactor={0.5} angleOffset={0} seed={111} positionOffset={{ x: 64, y: -196 }} />
                    <BlurrySphere style={style2} sizeCSS={sizeCSS2} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} jitterFactor={0.5} angleOffset={0} seed={112} positionOffset={{ x: 0, y: -196 }} />
                    <BlurrySphere style={style3} sizeCSS={sizeCSS3} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} jitterFactor={0.5} angleOffset={0} seed={112} positionOffset={{ x: -64, y: -196 }} />
                </div>
            )}
        </div>
    )
}

const HeroImage = () => {

    const style1 = `bg-gradient-to-r from-drGreen to-black opacity-20 filter blur-2xl`;
    const style2 = `bg-gradient-to-r from-drGreen to-black opacity-30 filter blur-3xl`;

    const sizeCSS1 = 96;
    const sizeCSS2 = 96;

    const Spheres = ({ divRef }) => {
        return (
            <div className="absolute">
                <BlurrySphere style={style1} sizeCSS={sizeCSS1} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} jitterFactor={1.0} angleOffset={0} seed={111} positionOffset={{ x: -256, y: 0 - 128 }} />
                <BlurrySphere style={style2} sizeCSS={sizeCSS2} containingDivRef={divRef} isAnimated={!isMobile} followsMouse={false} jitterFactor={1.0} angleOffset={0} seed={112} positionOffset={{ x: -312, y: -16 - 128 }} />
            </div>
        );
    };

    const divRef = useRef(null);

    return (
        <PageSection>
            <div ref={divRef} className="flex justify-center mb-24 mt-36 md:mb-16 md:mt-24">
                <div className="flex flex-row items-center gap-8 md:w-1/2">
                    <div className="relative z-10 w-1/2">
                        <img
                            className="translate-y-1/4 duration-500 ease-in-out scale-110 md:scale-100 md:hover:scale-110"
                            src={hero_image_1}
                        />
                    </div>
                    <div className="relative z-10 w-1/2">
                        <img
                            className="duration-500 ease-in-out scale-110 md:scale-100 md:hover:scale-110"
                            src={hero_image_2}
                        />
                    </div>
                    {isMobile ? (
                        <div className="relative">
                            <div className="absolute z-0 right-1/2 -translate-x-1/2 h-32 w-32 rounded-full opacity-70 bg-drGreen blur-3xl" />
                        </div>
                    ) : (
                        <Spheres divRef={divRef} />
                    )}
                </div>
            </div>
        </PageSection>
    );
};

const TitleAndTextBlock = ({ title, text, textClassName, titleClassName, className }) => {
    return (
        <div className={`flex flex-col gap-8 items-left ${className}`}>
            <h2 className={`text-white font-gilroy font-bold text-2xl ${titleClassName}`}>
                {title}
            </h2>
            <p className={`text-drcGray font-gilroy text-xl ${textClassName}`}>
                {text}
            </p>
        </div>
    )
}

const ScreenPage = ({ title, description, srcs, titleClassName, srcClassNames, srcDivClassNames, stickyMenu = true, dynamicHeight = true, children }) => {

    const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
    const [internalCarouselIndex, setInternalCarouselIndex] = useState(0);
    const [fadeStates, setFadeStates] = useState(srcs.map(() => 'opacity-0'));
    const [desktopItems, setDesktopItems] = useState(0);
    const defaultSwipeTolerance = 50;
    const [carouselItemHeight, setCarouselItemHeight] = useState(0);
    const [externalSwipeTolerance, setExternalSwipeTolerance] = useState(defaultSwipeTolerance);

    const carouselRef = useRef(null);
    const itemRef = useRef(null);

    const adjustCarouselHeight = () => {
        if (carouselRef.current) {
            const root = carouselRef.current.querySelector(".carousel-root");
            const activeSlide = carouselRef.current.querySelector(`.carousel-root .slide.selected .grid`);
            if (root && activeSlide) {
                const height = activeSlide.clientHeight;
                root.style.height = `${height}px`;
                setCarouselItemHeight(height);
            }
        }
    };

    const [isSticky, setIsSticky] = useState(false);
    const sentinelRef = useRef(null);
    const scrollToSentinelRef = useRef(null);

    const handleInternalCarouselChange = (index, numItems, item) => {

        setDesktopItems(numItems);

        if (index == 0 && internalCarouselIndex == numItems - 1) {
            handleButtonSelection(0);
        } else if (index == numItems - 1 && internalCarouselIndex == 0) {
            handleButtonSelection(selectedButtonIndex - 1);
        }
        // NOTE: carousel index has to be set based on external carousel direction approach
        setInternalCarouselIndex(index)
    }

    const scrollToSentinel = () => {
        if (isSticky && scrollToSentinelRef.current) {
            scrollToSentinelRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleButtonSelection = (index, trigger) => {
        if (index === selectedButtonIndex) return;

        if (!isMobile && index == srcs.length - 1 && desktopItems > 1) {
            setExternalSwipeTolerance(1000000);
        } else {
            setExternalSwipeTolerance(defaultSwipeTolerance);
        }

        scrollToSentinel();
        if (dynamicHeight) {
            setTimeout(adjustCarouselHeight, 300);
        }

        let newFadeStates = [...fadeStates];
        newFadeStates[selectedButtonIndex] = 'opacity-0';
        newFadeStates[index] = 'opacity-100';
        setFadeStates(newFadeStates);

        if (selectedButtonIndex == 1 && index == 2) {
            // approached desktop carousel from the left
            setInternalCarouselIndex(0);

        } else if (selectedButtonIndex == 0 && index == 2) {
            // approached desktop carousel from the right
            // but internal index depends on if swiped or clicked
            if (trigger == "swipe") {
                setInternalCarouselIndex(desktopItems - 1);
            } else {
                // clicked
                setInternalCarouselIndex(0);
            }
        }

        setSelectedButtonIndex(index);
    };

    useEffect(() => {
        let newFadeStates = [...fadeStates];
        newFadeStates[selectedButtonIndex] = 'opacity-100';
        setFadeStates(newFadeStates);

        const observer = new IntersectionObserver(([entry]) => {
            // The Menu becomes sticky when the sentinel element is no longer visible
            setIsSticky(!entry.isIntersecting);
        }, {
            rootMargin: '-16px 0px 0px 0px', // Adjust this based on the sticky position of the Menu
            threshold: [0]
        });

        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }

        const handleLoad = () => {
            adjustCarouselHeight();
        };

        if (dynamicHeight) {
            adjustCarouselHeight();
            window.addEventListener('resize', adjustCarouselHeight);
            window.addEventListener('load', handleLoad);
        }

        return () => {
            if (sentinelRef.current) {
                observer.unobserve(sentinelRef.current);
            }
            if (dynamicHeight) {
                window.removeEventListener('load', handleLoad);
                window.removeEventListener('resize', adjustCarouselHeight);
            }
        };

    }, [selectedButtonIndex, sentinelRef]);


    const Button = ({ label, isSelected, onSelect, isSticky }) => {

        const [doAnimate, setDoAnimate] = useState(false);
        const [doTransition, setDoTransition] = useState(true);
        const wasSelectedRef = useRef(isSelected); // Using ref to track previous selected state
        const wasStickyRef = useRef(isSticky); // Using ref to track previous selected state

        const getOpacityClass = () => {
            if (doTransition) {
                if (isSelected && doAnimate) {
                    return 'bg-opacity-20';
                } else if (isSticky && doAnimate) {
                    return 'bg-opacity-0 md:hover:bg-opacity-10 duration-500';
                } else {
                    return 'bg-opacity-0 md:hover:bg-opacity-10 duration-500';
                }
            } else {
                if (isSelected) {
                    return 'bg-opacity-20';
                } else if (isSticky) {
                    return 'bg-opacity-0 md:hover:bg-opacity-10 duration-500';
                } else {
                    return 'bg-opacity-0 md:hover:bg-opacity-10 duration-500';
                }
            }
        };

        useEffect(() => {
            const prevSelected = wasSelectedRef.current;
            const prevSticky = wasStickyRef.current;

            // TODO: this logic doesn't work, it's always either `true, true` or `false, false`
            if (prevSelected === isSelected) {
                setDoTransition(false);
            }

            if (prevSticky === isSticky) {
                setDoTransition(false);
            }

            setTimeout(() => {
                setDoAnimate(true);
            }, 20);

            wasSelectedRef.current = isSelected;
            wasStickyRef.current = isSticky;
        }, [isSelected, isSticky]);

        const transitionClass = doTransition ? 'duration-500 transition-all ease-in-out' : '';
        const opacityClass = getOpacityClass();

        return (
            <div onClick={() => onSelect()} className={`px-4 py-2 md:px-6 md:py-4 bg-codecButton ${transitionClass} ${opacityClass} cursor-pointer rounded-[96px]`}>
                <span className="text-white font-gilroy select-none">
                    {label}
                </span>
            </div>
        );
    };

    const Menu = ({ labels, selectedButtonIndex, onSelectButton, isSticky }) => {
        return (
            <div className="flex justify-center">
                <div className={`flex flex-row gap-4 md:gap-24 bg-darkblack rounded-full px-2 backdrop-blur-md p-2 transition-all duration-500 ${isSticky ? 'bg-opacity-75' : 'bg-opacity-0'}`}>
                    {labels.map((label, index) => (
                        <Button
                            key={index}
                            label={label}
                            isSelected={index === selectedButtonIndex}
                            onSelect={() => onSelectButton(index, "click")}
                            isSticky={isSticky}
                        />
                    ))}
                </div>
            </div>
        );
    };

    const Arrow = ({ src, isNext }) => {
        return (
            <div className={`absolute z-10 ${isNext ? 'right-8' : 'left-8'} top-1/2 opacity-50 hover:scale-110 hover:opacity-100 duration-500 cursor-pointer`}>
                <img
                    className="h-12 w-12"
                    src={src}
                />
            </div>
        )
    }

    return (
        <PageSection>
            <div className="flex flex-col gap-10 md:gap-20">
                <div className='flex flex-col gap-8 md:gap-8 justify-center'>

                    <div className="relative">
                        <div ref={sentinelRef} className="h-1" />
                        <SectionHeader label={title} className={`z-10 bg-darkblack pb-8 rounded-xl bg-opacity-0 duration-500 ${!isMobile && isSticky && stickyMenu ? 'xl:sticky xl:backdrop-blur-md xl:bg-opacity-50 xl:py-2 xl:pr-2 top-6' : ''} ${titleClassName}`} />

                        {description == null ? (null) :
                            (
                                <p className="text-drcGray font-gilroy text-xl">
                                    {description}
                                </p>
                            )
                        }

                        <div ref={scrollToSentinelRef} className="h-1" />
                        <div className={`${stickyMenu ? 'sticky top-12 md:top-0 pt-4 z-10' : ''} pb-8`}>
                            <Menu
                                labels={["Mobile", "Tablet", "Desktop"]}
                                selectedButtonIndex={selectedButtonIndex}
                                onSelectButton={(index, trigger) => (handleButtonSelection(index, trigger))}
                                isSticky={isSticky && stickyMenu}
                            />
                        </div>

                        <div ref={carouselRef}>
                            <Carousel
                                onChange={(index, item) => (handleButtonSelection(index, "swipe"))}
                                statusFormatter={(index, total) => ("")}
                                showIndicators={false}
                                renderArrowNext={() => { }}
                                renderArrowPrev={() => { }}
                                transitionTime={500}
                                infiniteLoop={true}
                                dynamicHeight={false}
                                swipeScrollTolerance={externalSwipeTolerance}
                                emulateTouch={true}
                                preventMovementUntilSwipeScrollTolerance={true}
                                showThumbs={false}
                                swipeable={true}
                                selectedItem={selectedButtonIndex}
                            >{srcs.map((srcList, index) => (
                                <div ref={itemRef} key={index} className="flex justify-center">
                                    <div className={`grid ${(srcList.length == 1 || index === srcs.length - 1 == 1) ? 'grid-cols-1' :
                                        srcList.length == 2 ? 'grid-cols-1 md:grid-cols-2' :
                                            srcList.length == 3 ? 'grid-cols-1 md:grid-cols-3' :
                                                srcList.length == 4 ? 'grid-cols-1 md:grid-cols-4' : 'grid-cols-2'
                                        } items-center justify-center gap-10 transition-opacity opacity-0 duration-500 ${fadeStates[index]} ${srcDivClassNames?.[index] ?? ''}`}>
                                        {( srcList.map((src, srcIndex) => (
                                            <div key={srcIndex} className={`relative ${srcClassNames?.[index]?.[srcIndex] ?? ''}`}>
                                                <img
                                                    className="max-h-[496px] object-scale-down md:max-h-[696px] duration-500 ease-out select-none"
                                                    src={src}
                                                    onLoad={() => { setTimeout(adjustCarouselHeight, 4000) }}
                                                />
                                            </div>
                                        )))}
                                    </div>
                                    {children}
                                </div>
                            ))}
                            </Carousel>
                        </div>

                    </div>
                </div>
            </div>
        </PageSection>
    );
};

const VideoCodec = () => {

    const { ref, inView, entry } = useInView({
        threshold: 0,
    })

    return (
        <PageWithCoverHeaderFooter title={"Video Codec"} className={"bg-darkblack"}>

            <SEO
                title="Video Codec"
                description="Explore a novel type of video app to showcase AI video compression."
            />

            <PageColumn>

                <Logo />

                <ProjectHeader
                    className={"-my-32 md:-my-32"}
                    description={"Designs for novel AI video technology."}
                    about={"I had the exciting opportunity to develop a MacOS & iOS application for a London-based startup showcasing their cutting-edge AI video codec. This cross-device application aimed to demonstrate the prowess of their video compression technology."}
                    timeframe={"September 2023 | 2 Weeks"}
                    role={"UX/UI Designer & Researcher"}
                    platforms={"Mobile, Tablet, Desktop"}
                    category={"Video Streaming"}
                />

                <HeroImage />

                <PageSection>

                    <div className='flex flex-col gap-8 justify-center'>

                        <div className='flex flex-col gap-8 md:gap-16 justify-center'>
                            <SectionHeader label={"Research"} />

                            <TitleAndTextBlock
                                textClassName={"md:w-2/3"}
                                title={"Design Approach"}
                                text={"In this project I opted for a hands-on approach: diving straight into Figma for design exploration. The goal was to create a fresh and modern take on a video-centric application to demonstrate the novel proprietary codec in three different ways: live video streaming, video-on-demand and offline compression of any files."}
                            />
                        </div>
                    </div>

                </PageSection>

                <FullScreenImage
                    src={collage_1_desktop}
                    srcMobile={collage_1_mobile}
                />
                <PageSection>
                    <div className="flex flex-col md:flex-row justify-between gap-16">
                        <TitleAndTextBlock
                            title={"Client Collaboration"}
                            text={"The client had ideas of the technical features of the application, such as the video call aspect, but no way to formalize the structure and design. But with screen-sharing and white-boarding you can solve most problems. I developed the design based on joint discussions over Zoom. The instant feedback really sped-up the design process and helped me incorporate all the technical requirmements faster."}
                        />
                        <TitleAndTextBlock
                            title={"Challenges"}
                            text={"I have never designed a video streaming solution or chat application before, though I have used a few, this application goes deeper than any I have seen. It is a mix of a benchmarking application and casual streaming app. Finding the right balance between simple design and complex metrics was challenging."}
                        />
                    </div>

                </PageSection>

                <ScreenPage
                    title={"Start Screens"}
                    description={"The initial screen presents three different features: benchmarking, video call and video-playback."}
                    srcs={[
                        // Mobile
                        [start_screen_mobile_1, start_screen_mobile_2],
                        // Tablet
                        [start_screen_tablet_1, start_screen_tablet_2],
                        // Desktop
                        [start_screen_desktop_1],
                    ]}
                    srcDivClassNames={["md:!grid-cols-2", "md:!grid-cols-2", "!grid-cols-1"]}
                />

                <ScreenPage
                    title={"Key Features"}
                    description={"As a way of demonstraing the performance of the video codec, the user is able to select any video and compress it in real-time, with practical insights such as power, file size and speed."}
                    srcs={[
                        // Mobile
                        [key_features_mobile_1, key_features_mobile_2, key_features_mobile_3, key_features_mobile_4],
                        // Tablet
                        [key_features_tablet_1, key_features_tablet_2, key_features_tablet_3],
                        // Desktop
                        [key_features_desktop_1, key_features_desktop_2, key_features_desktop_3],
                    ]}
                    srcDivClassNames={["md:!grid-cols-2", "md:!grid-cols-2", "!grid-cols-1"]}
                />

                <ScreenPage
                    title={"Live Call"}
                    description={"Real-time video calls was added to demonstrate the speed of the AI video codec. The application connects to the device via an IP address."}
                    srcs={[
                        // Mobile
                        [live_call_mobile_1, live_call_mobile_2, live_call_mobile_3, live_call_mobile_4],
                        // Tablet
                        [live_call_tablet_1, live_call_tablet_2, live_call_tablet_3, live_call_tablet_4],
                        // Desktop
                        [live_call_desktop_1, live_call_desktop_2, live_call_desktop_3],
                    ]}
                    srcDivClassNames={["md:!grid-cols-2", "md:!grid-cols-2", "!grid-cols-1"]}
                />

                <ScreenPage
                    title={"Video-on-Demand"}
                    description={"Video-on-demand is a fancy name for video streaming (you get the video when you demand it—just wait for it to buffer 😤). The filter buttons offer a curated selection of videos to demonstrate the versatility of the codec."}
                    srcs={[
                        // Mobile
                        [vod_mobile_1, vod_mobile_2],
                        // Tablet
                        [vod_tablet_1, vod_tablet_2],
                        // Desktop
                        [vod_desktop_2, vod_desktop_1],
                    ]}
                    srcDivClassNames={["md:!grid-cols-2", "md:!grid-cols-2", "!grid-cols-1"]}
                />

                <div className="relative">
                    <div className="absolute z-0 top-1/2">
                        <LandscapeViewSpheres />
                    </div>
                    <ScreenPage
                        title={"Landscape View"}
                        description={null}
                        srcs={[
                            // Mobile
                            [landscape_mobile_1, landscape_mobile_2],
                            // Tablet
                            [landscape_tablet_1, landscape_tablet_2],
                            // Desktop
                            [landscape_desktop_1],
                        ]}
                        titleClassName={"!flex justify-center"}
                        srcClassNames={[
                            ["pr-4 md:-translate-x-1/4", "pl-4 md:translate-x-1/4"],
                            ["pr-4 md:-translate-x-1/4", "pl-4 md:translate-x-1/4"],
                            [""]
                        ]}
                        srcDivClassNames={["!grid-cols-1 md:w-1/2 z-10", "!grid-cols-1 md:w-1/2 z-10", "!grid-cols-1"]}
                        dynamicHeight={true}
                        stickyMenu={false}
                    />
                </div>

                <PageSection className={"z-10"}>
                    <div className="flex justify-center">
                        <TitleAndTextBlock
                            title={"What I Learnt"}
                            text={(
                                <span>
                                    I love jumping into a project when I know nothing about the field.
                                    This project was particularly challenging due to all the technical details
                                    about compression, hardware, encoding, decoding, power, benchmarking.
                                    Crafting a functional and beautiful UI is hard when there are so many features.
                                    I wanted to create an application that was eye-catching but offered layers of
                                    complexity that the user could unravel.
                                    <br />
                                    <br />
                                    Thanks to the collaborative discussions I fairly quickly found
                                    a direction for the layout and theme. Complex statistics and details
                                    are contained within nested containers and graphs are used frequently for
                                    better data-reporting UX.
                                </span>
                            )}
                            textClassName={"md:w-1/2"}
                            className={"md:!items-end md:justify-left"}
                            titleClassName={"md:w-1/2 md:right-0"}
                        />
                    </div>
                </PageSection>

                <PageSection>
                    <div className="flex flex-col gap-16">
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col md:flex-row gap-8 md:items-center">
                                <div className="flex flex-row items-center gap-4">
                                    <div className="h-2 w-2 bg-drGreen rounded-full" />
                                    <h1 className="text-xl md:text-2xl text-drcGray uppercase tracking-widest">
                                        Follow-up
                                    </h1>
                                </div>
                                <span className="hidden md:inline text-drcGray text-2xl">{"|"}</span>
                                <h2 className="hidden md:inline text-xl md:text-2xl text-drcGray font-bold md:font-normal tracking-wide">
                                    Live Presentations
                                </h2>
                            </div>
                            <div className="md:w-2/3">
                                <p className="text-drcGray text-xl">
                                    As a finale for this product, I wanted to mention some cool places were my work was presented.
                                    Though the number of people that interact with the products
                                    that I have designed is large, it has never been presented directly in front of a massive
                                    audiences at the world stage or to CEOs of multi-billion dollar corporations. All of that suddenly changed...
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col-reverse md:flex-row gap-8 md:gap-16 items-center">
                            <img
                                className="md:rounded-xl md:w-1/2"
                                src={video_app_at_microsoft}
                            />
                            <TitleAndTextBlock
                                title={"Microsoft Headquarters"}
                                text={(
                                    <span>
                                        <span>
                                            {"I was deligthed to hear that the application had been presented to individuals at the Microsoft headquarters in Redmond, USA. This is an earlier iteration of the "}
                                        </span>
                                        <span className="font-thin text-gray-500 uppercase tracking-widest">
                                            start screen
                                        </span>
                                        <span>
                                            {" before the video-on-demand section was added."}
                                        </span>
                                    </span>
                                )}
                                className={"md:w-1/2"}
                            />
                        </div>

                        <div className="flex flex-col md:flex-row gap-8 md:gap-16 items-center">
                            <TitleAndTextBlock
                                title={"Intel Innovations"}
                                text={"You might have heard of Apple's yearly event called WWDC? Well, it turns out Intel has a similar event called Innovations. In addition to the screens shown above, I was asked to create a few variations for a very special demo. This demo version was presented at the Intel Innovations 2023, during the keynote speech by Intel CEO Pat Gelsinger (pictured on the lower left)."}
                                className={"md:w-1/2"}
                            />
                            <img
                                className="md:rounded-xl md:w-1/2"
                                src={video_app_at_intel}
                            />
                        </div>
                    </div>
                </PageSection> 

                <NextProject>
                    <DrcProject />
                </NextProject>

            </PageColumn>
        </PageWithCoverHeaderFooter>
    );
};

export default VideoCodec;