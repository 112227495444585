import { useInView } from "react-intersection-observer";

const FullScreenImage = ({src, srcMobile, className}) => {

    const { ref, inView, entry } = useInView({
        threshold: 0,
    })

    return (
        <div className={`flex justify-center ${className}`}>
            <div ref={ref} className={`flex flex-row transition-all 4xl:w-3/4 max-w-screen-3xl duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <img
                    className='hidden md:block 4xl:rounded-lg w-full h-full select-none'
                    src={src}
                    alt={`desktop image`}
                />
                <img
                    className='md:hidden rounded w-full h-full select-none'
                    src={srcMobile}
                    alt={`mobile image`}
                />
            </div>
        </div>
    );
};

export default FullScreenImage;