import React, { useEffect, useState, useRef, useCallback } from 'react';
import { isSafari } from 'react-device-detect';

const RandomGenerator = (props) => {
  const { seed } = props
  const [seed_, setSeed] = useState(seed);

  const generate = () => {
    var x = Math.sin(seed_)
    setSeed(seed + 1);
    return x - Math.floor(x);
  }

  return { generate };

}

const Jitter = (props) => {
  const { degrees, offset, randomGenerator, factor = 2.0 } = props;
  const [factor_, setFactor] = useState(factor);

  const degressToRads = 2 * Math.PI / 360;

  const compute = useCallback((angle) => {
      angle = angle - offset;
      let sum = 0.0;
      for (var i = 0; i < degrees; ++i) {
        let value = randomGenerator.generate() + 0.5
        // console.log(value);

        let func = (x) => { return Math.cos(x); };
        if (value < 1.0) { func = (x) => { return Math.sin(x); }; }

        sum += value * func(angle * value * degressToRads)
      }
      return sum * factor_;

      }, [offset, degrees]
  );

  return { compute } ;
}

const BlurrySphere = (props) => {

  const { style, sizeCSS, containingDivRef, angleOffset, doAnimate = true, followsMouse = true, jitterFactor = 2.0, movementSpeedFactor = 0.004, positionOffset = {x: 0, y: 0}, seed = 1 } = props;
  const radius = (sizeCSS * 4) / 2; // in pixels


  const [initialPosition, setInitialPosition] = useState({
    x: (window.innerWidth / 2) - radius + positionOffset.x,
    y: 128 + radius / 2 + positionOffset.y,
  })

  const randomGenerator = RandomGenerator({seed: seed});

  const jitterX = Jitter({degrees: 5, factor: jitterFactor, offset: angleOffset, randomGenerator: randomGenerator});
  const jitterY = Jitter({degrees: 5, factor: jitterFactor, offset: angleOffset, randomGenerator: randomGenerator});

  const [positionX, setPositionX] = useState(initialPosition.x);
  const [positionY, setPositionY] = useState(initialPosition.y);
  const previousPositionX = useRef(initialPosition.x);
  const previousPositionY = useRef(initialPosition.y);
  const mousePositionX = useRef(initialPosition.x + radius);
  const mousePositionY = useRef(initialPosition.y);
  const requestRef = useRef();

  useEffect(() => {

    const containingDiv = containingDivRef.current;

    const handleMouseMovement = (event) => {
      const { clientX, clientY } = event;
      mousePositionX.current = clientX;
      mousePositionY.current = clientY;
    }

    const handleMouseMove = (event) => {
      handleMouseMovement(event);
    };

    const handleMouseLeave = (event) => {
      mousePositionX.current = initialPosition.x + radius + positionOffset.x;
      mousePositionY.current = initialPosition.y + radius + positionOffset.y;
    };

    const handleResize = (event) => {
      initialPosition.x = window.innerWidth / 2 - radius;
      initialPosition.y = window.innerHeight / 2 - radius;
      mousePositionX.current = initialPosition.x + radius + positionOffset.x;
      mousePositionY.current = initialPosition.y + radius + positionOffset.y;
    };

    window.addEventListener("resize", handleResize);
    if (followsMouse) {
      containingDiv.addEventListener("mousemove", handleMouseMove);
      containingDiv.addEventListener("mouseleave", handleMouseLeave);
    }

    const boundaries = {
      x: {
        max: initialPosition.x + window.innerHeight / 4,
        min: initialPosition.x - window.innerHeight / 4,
      },
      y: {
        max: initialPosition.y + radius / 4,
        min: initialPosition.y - radius,
      }
    }

    let t = angleOffset;
    const animate = () => {

      const jitterValueX = jitterX.compute(t);
      const jitterValueY = jitterY.compute(t);

      const distanceXToCenter = previousPositionX.current - initialPosition.x;
      const distanceYToCenter = previousPositionY.current - initialPosition.y;

      const distanceX = (clamp(mousePositionX.current - radius, boundaries.x.max, boundaries.x.min)) - previousPositionX.current;
      const distanceY = (clamp(mousePositionY.current - radius, boundaries.y.max, boundaries.y.min)) - previousPositionY.current;

      let newPositionX = (previousPositionX.current + distanceX * movementSpeedFactor + jitterValueX / (Math.pow(Math.abs(distanceXToCenter), 0.5) + 1)); 
      let newPositionY = (previousPositionY.current + distanceY * movementSpeedFactor + jitterValueY / (Math.pow(Math.abs(distanceYToCenter), 0.5) + 1));
      newPositionX = clamp(newPositionX, boundaries.x.max, boundaries.x.min);
      newPositionY = clamp(newPositionY, boundaries.y.max, boundaries.y.min);

      t = (t + 1) % 360;

      previousPositionX.current = newPositionX;
      previousPositionY.current = newPositionY;

      setPositionX((prevPosX => previousPositionX.current));
      setPositionY((prevPosY => previousPositionY.current));

      requestRef.current = requestAnimationFrame(animate);
    };

    // All animations are jank on Safari!
    if (doAnimate && !isSafari) {
      animate();
    }

    return () => {
      if (containingDiv !== null) {
        containingDiv.removeEventListener("mousemove", handleMouseMove);
        containingDiv.removeEventListener("mouseleave", handleMouseLeave);
      }
      if (window !== null) {
        window.removeEventListener("resize", handleResize);
      }
      cancelAnimationFrame(requestRef.current);
    }
  }, []);

  const clamp = (value, max, min) => {
    return value;
    // if (value >= max) {
    //   return max;
    // }
    // else if (value <= min) {
    //   return min;
    // } else {
    //   return value;
    // }
  }

  return (
      <div
        // className={`absolute w-${sizeCSS} h-${sizeCSS} rounded-full bg-gradient-to-r from-cyan-500 via-blue-900 to-blue-500 opacity-20 filter blur-3xl pointer-events-none`}
        // className={`absolute w-96 h-96 rounded-full bg-blue-900 opacity-40 filter blur-3xl pointer-events-none`}
        // className={`absolute w-96 h-96 rounded-full bg-gradient-to-r from-cyan-500 to-blue-900 opacity-20 filter pointer-events-none`}

        className={`absolute w-${sizeCSS} h-${sizeCSS} rounded-full pointer-events-none ${style}`}
        style={{ transform: `translate(${positionX}px, ${positionY}px)` }}

      ></div>
  );
}

export default BlurrySphere;