import Project from './Project';
import {
    drc_preview, drc_preview_mobile, skillworks_preview,
    skillworks_preview_mobile, ski_preview, ski_preview_mobile,
    turandot_preview, turandot_preview_mobile, video_app_preview,
    video_app_preview_mobile
} from '../assets';
import PageColumn from './PageColumn';
import PageSection from './PageSection';

export const DrcProject = (props) => (
    <Project
        title={"DRC"}
        subtitle={"Cloud Computing Platform."}
        link={"drc"}
        description={"Platform dedicated to deep learning experiments and cloud compute resource management."}
        src={drc_preview}
        srcMobile={drc_preview_mobile}
        {...props}
    />
);

export const SkillWorksProject = (props) => (
    <Project
        title={"SkillWorks"}
        subtitle={"Learning Management System (LMS)."}
        link={"skillworks"}
        description={"Educational platform designed to empower employees through video tutorials and courses."}
        src={skillworks_preview}
        srcMobile={skillworks_preview_mobile}
        {...props}
    />
);

export const TurandotProject = (props) => (
    <Project
        title={"Turandot"}
        subtitle={"Exquisite Asian Cuisine."}
        description={"I was lead designer of the application for the restaurant Turandot."}
        link={null}
        src={turandot_preview}
        srcMobile={turandot_preview_mobile}
        sideBySide={true}
        comingSoon={true}
        {...props}
    />
);


export const SkiResortProject = (props) => (
    <Project
        title={"Shymbulak"}
        subtitle={"Skiresort Management."}
        description={"I was the main product designer for a skiresort management application."}
        src={ski_preview_mobile}
        srcMobile={ski_preview_mobile}
        sideBySide={true}
        link={null}
        comingSoon={true}
        {...props}
    />
);

export const VideoAppProject = (props) => (
    <Project
        title={"Video Codec"}
        subtitle={"Video Conferencing."}
        description={"Lead designer of a novel video chat application."}
        link={"videocodec"}
        src={video_app_preview}
        srcMobile={video_app_preview_mobile}
        comingSoon={false}
        {...props}
    />
);

export const ProjectSection = ({ children }) => (
    <PageSection subClassName={"!px-0"}>
        {children}
    </PageSection>
)

const SelectedProjects = () => {

    return (
        <PageColumn>
            <ProjectSection>
                <VideoAppProject />
            </ProjectSection>

            <ProjectSection>
                <DrcProject />
            </ProjectSection>

            <ProjectSection>
                <SkillWorksProject />
            </ProjectSection>

            {/* Mobile Only */}
            <div className='md:hidden flex flex-col md:flex-row justify-between gap-16'>
                <SkiResortProject />
                <TurandotProject />
            </div>

            {/* Desktop Only */}
            <PageSection>
                <div className='hidden md:flex flex-row justify-between gap-8'>
                    <div className='w-1/2'>
                        <SkiResortProject variation1={false} />
                    </div>
                    <div className='w-1/2'>
                        <TurandotProject variation1={false} />
                    </div>
                </div>
            </PageSection>

        </PageColumn>
    )
}

export default SelectedProjects;