
const Column = (props) => {
    const { label, value } = props;
    return (
        <div className='flex flex-col gap-0.5 md:gap-1 font-gilroy'>
            <h4 className='text-gray-500 text-sm md:text-base font-bold'>
                {label}
            </h4>
            <p className='text-white text-sm md:text-base'>
                {value}
            </p>
        </div>
    )
}

const ProjectInfo = (props) => {
    const { timeframe, role, platforms, category } = props
    return (
        <div>
            {/* Desktop Only */}
            <div className='hidden md:flex flex-row gap-32 justify-between'>
                <Column label={"Timeframe"} value={timeframe} />
                <Column label={"Role"} value={role} />
                <Column label={"Platforms"} value={platforms} />
                <Column label={"Category"} value={category} />
            </div>

            {/* Mobile Only */}
            <div className='md:hidden flex flex-col gap-4 md:gap-8'>
                <div className='flex flex-row gap-8 justify-start'>
                    <div className='w-1/2'>
                        <Column label={"Timeframe"} value={timeframe} />
                    </div>
                    <div className='w-1/2'>
                        <Column label={"Role"} value={role} />
                    </div>
                </div>
                <div className='flex flex-row gap-8 justify-start'>
                    <div className='w-1/2'>
                        <Column label={"Platforms"} value={platforms} />
                    </div>
                    <div className='w-1/2'>
                        <Column label={"Category"} value={category} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectInfo;
