import React, { useState } from 'react';

const Hero = () => {
  return (
      <div className="flex flex-col gap-1 items-start uppercase font-normal font-gilroy text-4xl md:text-6xl">
          <div className=" text-white rounded select-none transition-all pl-1 pr-1 duration-1000 opacity-75 bg-gradient-to-r from-resumeButtonColor1 to-resumeButtonColor2 hover:opacity-100">
              <span className='relative top-[5px]'>
                  DESIGN.
              </span>
          </div>
          <span className='text-gray-300 font-bold select-none'>
              BY KADYRAI.<br />
          </span>
      </div>
  );
};


const PasswordPrompt = ({ password, setPassword, handleLogin, isPending }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleButtonClick = async () => {
    try {
      const isLoginSuccessful = await handleLogin();
      if (!isLoginSuccessful) {
        setErrorMessage('Incorrect password. Please try again.');
        setPassword('');
      } else {
        setErrorMessage('');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="bg-darkblack bg-opacity-30">
      <div className="flex items-center justify-center">
        <div className="bg-swBlack bg-opacity-30 p-8 rounded-xl shadow-md w-full max-w-sm">
          <Hero />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 mb-4 border bg-swBlack border-gray-300 text-white rounded focus:outline-none"
            placeholder="Enter Password"
          />
          {errorMessage && (
            <div className="text-red-300 mb-4">
              {errorMessage}
            </div>
          )}
          <button
            onClick={handleButtonClick}
            className={`w-full bg-gradient-to-r from-pinkDesigner to-purpleParticle text-white p-2 rounded ${password.length > 0 && !isPending ? 'opacity-90' : 'opacity-40'} transition-opacity duration-500`}
            disabled={isPending || password.length === 0}
          >
            {isPending ? 'Loading...' : 'Enter'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordPrompt;
