
const pushCustomTagToClarity = (tagName, tagValue) => {
  if (window.clarity) {
    window.clarity("set", tagName, tagValue);
  }
}

const pushCustomEventToClarity = (eventName) => {
  if (window.clarity) {
    window.clarity("event", eventName);
  }
}

export { pushCustomTagToClarity, pushCustomEventToClarity };