import React, { useState } from 'react';

import { useInView } from 'react-intersection-observer';
import { isMobile } from "react-device-detect";
import ProjectThumbnail from './ProjectThumbnail';
import { Link } from 'react-router-dom';

const Project = ({ title, subtitle, description, src, srcMobile, link, sideBySide = false, comingSoon = false, variation1 = true, showDescription = true }) => {

    const { ref, inView, entry } = useInView({
        threshold: 0,
    })

    const DescriptionVariation = () => (
        variation1 ?
            (
                <div className="flex flex-col gap-8 md:flex-row justify-between pl-8 md:pl-0 items-start">
                    <div className='flex flex-col justify-between'>
                        <p className='text-4xl text-white font-bold'>
                            {title}
                        </p>
                        <p className='text-2xl text-gray-500'>
                            {subtitle}
                        </p>
                    </div>
                    <div className="md:w-1/2">
                        <p className={`${showDescription ? 'md:block' : ''} hidden  text-gray-500 text-sm 3xl:text-base break-normal text-left md:text-right`}>
                            {description}
                        </p>
                    </div>
                </div>
            )
            :
            (
                <div className="flex flex-col gap-2 justify-between items-start">
                    <div className='flex flex-row justify-between w-full items-end'>
                        <p className='text-4xl text-white font-bold'>
                            {title}
                        </p>
                        <p className='text-2xl text-gray-500'>
                            {subtitle}
                        </p> </div>
                    <div>
                        <p className={`${showDescription ? 'md:block' : ''} hidden  text-gray-500 text-sm 3xl:text-base break-normal text-right md:text-left`}>
                            {description}
                        </p>
                    </div>
                </div>
            )
    );

    const Content = () => (
        <div className={`group flex flex-col font-gilroy gap-8 transition-all duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
            <ProjectThumbnail
                isMobile={isMobile}
                comingSoon={comingSoon}
                src={src}
                srcMobile={srcMobile}
                sideBySide={sideBySide}
            />
            <DescriptionVariation />
        </div>
    )

    return (
        <div ref={ref}>
            { link ?
                <Link to={`/project/${link}`}>
                    <Content />
                </Link>
            :
                <Content />
            }
        </div>
    )
}

export default Project;